import { ReactNode, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { get } from 'lodash';
import 'components/customers/style.scss';
import globalCustomersService from 'services/customerService';
import {
  ICustomerDetails,
  IFormFilter,
  ITableFilter,
  SearchCustomerBody,
} from 'interface/customerInterface';
import PaginationCommon from 'components/common/PaginationCommon';
import { Checkbox } from 'shared-components';
import StatusBadge from 'components/common/statusBadge';
import SideFilter from 'components/customers/customersFilter/sideFilter';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import { useSearchParams } from 'react-router-dom';
import { getCustomerDetailsPath } from 'router/constant';
import {
  detectBooleanValue,
  downloadCsv,
  formattedDate,
  getTotalPages,
  iterateHeadCellKeys,
  setPaginationCommon,
} from 'utils/helper';
import { Loader } from 'components/common/loader';
import Link from 'components/common/link';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';
import dayjs from 'dayjs';
import SearchFilter from 'components/common/searchFilter';
import { SearchProps } from 'components/transactions';
import { subscriptionStatus, trafficChannelData } from 'utils/constants';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ICustomerDetails) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

const placeholder = '-';
const headCells: readonly HeadCell[] = [
  {
    id: 'Subscription.CreatedAt',
    label: 'Enrollment date',
    key: 'enrollment_date',
    cellRender: row => {
      return (
        <Tooltip
          title={formattedDate(row?.Subscription?.CreatedAt)}
          placement="top"
          arrow>
          <span>{formattedDate(row?.Subscription?.CreatedAt, true)}</span>
        </Tooltip>
      );
    },
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'Email',
    label: 'Email',
    key: 'email',
    hide: false,
    cellRender: row => {
      return (
        <Link to={getCustomerDetailsPath(row.ID, row?.Store?.ID)}>
          <span className="text-as-link"> {`${row?.Email}`}</span>
        </Link>
      );
    },
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'FirstName',
    label: 'Customer Name',
    key: 'customer_name',
    cellRender: row => {
      return `${row?.FirstName ?? ''} ${row?.LastName ?? ''}`;
    },
    hide: false,
    showSortIcon: false,
  },
  {
    id: 'PhoneNumber',
    label: 'Phone',
    key: 'phone',
    cellRender: row => <p>{row?.PhoneNumber ? row.PhoneNumber : ''}</p>,
    hide: false,
    showSortIcon: false,
  },
  {
    id: 'Store.Name',
    label: 'Store',
    key: 'store',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'TrafficChannel',
    label: 'Traffic Channel',
    key: 'traffic_channel',
    cellRender: row => {
      return <p>{row?.TrafficChannel || '-'}</p>;
    },
    hide: false,
    showSortIcon: true,
    showInSearch: true,
    searchFiedtType: 'select',
    searchFieldOptions: trafficChannelData,
  },
  {
    id: 'RecentShippingAddress.Address1',
    label: 'Shipping Address',
    key: 'shipping_address',
    cellRender: row => {
      const { RecentShippingAddress } = row;
      return (
        <>{`${RecentShippingAddress.Address1 ?? ''}, ${RecentShippingAddress.Address2 ?? ''}`}</>
      );
    },
    hide: false,
    showSortIcon: false,
  },
  {
    id: 'Subscription.Status',
    label: 'Subscription Status',
    key: 'subscription_status',
    cellRender: row => {
      if (row?.Subscription?.Status === 'unknown') {
        return placeholder;
      }
      return <StatusBadge status={row.Subscription.Status} />;
    },
    hide: false,
    showSortIcon: true,
    showInSearch: true,
    searchFiedtType: 'select',
    searchFieldOptions: subscriptionStatus,
  },
  {
    id: 'Subscription.CurrentCycle',
    label: 'Subscription Cycle',
    key: 'subscription_cycle',
    hide: false,
    showSortIcon: true,
    showInSearch: true,
  },
  {
    id: 'Subscription.CreatedAt',
    label: 'Last Billing Date',
    key: 'last_billing_date',
    cellRender: row => {
      return (
        <Tooltip
          title={formattedDate(row?.Subscription?.CreatedAt)}
          placement="top">
          <span>{formattedDate(row?.Subscription?.CreatedAt, true)}</span>
        </Tooltip>
      );
    },
    hide: false,
    showSortIcon: true,
  },

  {
    id: 'Subscription.NextBillingDate',
    label: 'Next Subscription Billing Date',
    key: 'next_subscription_billing_date',
    cellRender: row => {
      return (
        <Tooltip
          title={formattedDate(row?.Subscription?.NextBillingDate)}
          placement="top">
          <span>
            {row.Subscription.Status ===
            SubscriptionStatus.subscriptionStatusCanceled
              ? '-'
              : formattedDate(row?.Subscription?.NextBillingDate, true)}
          </span>
        </Tooltip>
      );
    },
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'HasDisputed',
    label: 'Disputed Before',
    key: 'disputed_before',
    cellRender: row => {
      return (
        <div className={!row.HasDisputed ? 'success_text' : 'danger_text'}>
          {row.HasDisputed ? 'Yes' : 'No'}
        </div>
      );
    },
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'IsBlacklisted',
    label: 'Black Listed',
    key: 'black_listed',
    cellRender: row => {
      return (
        <div className={!row.IsBlacklisted ? 'success_text' : 'danger_text'}>
          {row.IsBlacklisted ? 'Yes' : 'No'}
        </div>
      );
    },
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'Tags',
    label: 'Tags',
    key: 'tags',
    cellRender: row => {
      return (
        <div className="tags_wrapper">
          {row.Tags?.map((tag, index) => {
            return (
              <span key={index} className="tag">
                {tag}
              </span>
            );
          })}
        </div>
      );
    },
    hide: false,
    showSortIcon: false,
  },
];

const filteredField = iterateHeadCellKeys([...headCells]);
export const defaultCustomerFilterValues = {
  Channels: [],
  EndTime: null,
  HasDisputed: false,
  IsBlacklisted: false,
  MaxLifetimeValue: '',
  MinLifetimeValue: '',
  StartTime: null,
  TrafficChannels: [],
  StoreIDs: [],
  CustomerEmails: [],
  PhoneNumbers: [],
  FirstName: '',
  LastName: '',
  SubscriptionStatus: [],
};

export default function Customers() {
  const [search, setSearchValue] = useState<SearchProps>({});
  const dispatch = useAppDispatch();
  const [customersList, setCustomersList] = useState<ICustomerDetails[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const storeIds = useAppSelector(state => state.storeIds.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const [Page, setPage] = useState<number>(
    Number(searchParams.get('Page') || 1),
  );
  const rowsPerPage = 25;
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const [selected, setSelected] = useState<Map<string, ICustomerDetails>>(
    new Map(),
  );
  const { customerColumnFilter } = useAppSelector(state => state.coloumnFilter);
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [headCellList, setHeadCellList] = useState<HeadCell[]>([
    ...headCells.map(value => {
      return {
        ...value,
        hide: customerColumnFilter?.includes(value.label),
      };
    }),
  ]);
  const [sideFormFilter, setSideFormFilter] = useState<IFormFilter>({
    Channels: searchParams.get('Channels')
      ? JSON.parse(searchParams.get('Channels') || '')
      : [],
    CustomerEmails: searchParams?.get('CustomerEmails')
      ? JSON.parse(searchParams.get('CustomerEmails') || '')
      : defaultCustomerFilterValues.CustomerEmails,
    PhoneNumbers: searchParams?.get('PhoneNumbers')
      ? JSON.parse(searchParams.get('PhoneNumbers') || '')
      : defaultCustomerFilterValues.PhoneNumbers,
    FirstName: searchParams?.get('FirstName')
      ? JSON.parse(searchParams.get('FirstName') || '')
      : defaultCustomerFilterValues.FirstName,
    LastName: searchParams?.get('LastName')
      ? JSON.parse(searchParams.get('LastName') || '')
      : defaultCustomerFilterValues.LastName,
    EndTime: searchParams.get('EndTime')
      ? dayjs(searchParams.get('EndTime')).tz()
      : defaultCustomerFilterValues.EndTime,
    HasDisputed: detectBooleanValue(searchParams.get('HasDisputed') || ''),
    IsBlacklisted: detectBooleanValue(searchParams.get('IsBlacklisted') || ''),
    StartTime: searchParams.get('StartTime')
      ? dayjs(searchParams.get('StartTime')).tz()
      : defaultCustomerFilterValues.StartTime,
    TrafficChannels: searchParams.get('TrafficChannels')
      ? JSON.parse(searchParams.get('TrafficChannels') || '')
      : defaultCustomerFilterValues.TrafficChannels,
    StoreIDs: searchParams.get('StoreIDs')
      ? JSON.parse(searchParams.get('StoreIDs') || '')
      : defaultCustomerFilterValues.StoreIDs,
    SubscriptionStatus: searchParams.get('SubscriptionStatus')
      ? JSON.parse(searchParams.get('SubscriptionStatus') || '')
      : defaultCustomerFilterValues.SubscriptionStatus,
  });
  const [filter, setFilter] = useState<ITableFilter>({
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Tags: [],
  });
  const [loading, setLoading] = useState(0);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [paginationData, setpaginationData] = useState<
    Map<number, ICustomerDetails[]>
  >(new Map());
  const handleRowChecked = (customer: ICustomerDetails, checked: boolean) => {
    if (checked) {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(customer.ID);
        return newSelected;
      });
    } else {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.set(customer.ID, customer);
        return newSelected;
      });
    }
  };

  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const filterSubmission = (formData: IFormFilter) => {
    if (!loading) {
      setSideFormFilter(formData);
      setPage(1);
    }
  };

  const selectAll = (checked: boolean) => {
    if (checked) {
      const allRows = new Map(
        customersList.map(row => {
          return [row.ID, row];
        }),
      );
      setSelected(allRows);
    } else {
      setSelected(new Map());
    }
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      Channels,
      TrafficChannels,
      StoreIDs,
      HasDisputed,
      IsBlacklisted,
      CustomerEmails,
      PhoneNumbers,
      FirstName,
      LastName,
      StartTime,
      EndTime,
      SubscriptionStatus,
    } = sideFormFilter;
    const { Tags, Descending, OrderBy, ...rest } = filter;
    const channels = TrafficChannels.map(temp => temp.value);
    const statusValues = SubscriptionStatus.map(temp => temp.value);
    const payload: SearchCustomerBody = {
      ...rest,
      Descending,
      OrderBy,
      Page: Page - 1 || 0,
      Limit: limit,
    };
    if (Tags?.length) payload.Tags = Tags;
    if (IsBlacklisted) payload.IsBlacklisted = IsBlacklisted;
    if (HasDisputed) payload.HasDisputed = HasDisputed;
    if (TrafficChannels?.length) payload.TrafficChannels = channels;
    if (SubscriptionStatus?.length) payload.SubscriptionStatus = statusValues;
    if (storeIds?.length) payload.StoreIDs = storeIds.map(store => store.ID);
    if (storefrontIds?.length) payload.StorefrontIDs = storefrontIds;
    if (CustomerEmails?.length) payload.CustomerEmails = CustomerEmails;
    if (PhoneNumbers?.length) payload.PhoneNumbers = PhoneNumbers;
    if (FirstName) payload.FirstName = FirstName;
    if (LastName) payload.LastName = LastName;
    if (StartTime) payload.StartTime = StartTime.tz().startOf('day').format();
    if (EndTime)
      payload.EndTime = EndTime.tz().add(1, 'day').startOf('day').format();
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    if (isSetSearchParams) {
      setSearchParams(
        {
          StoreIDs: JSON.stringify(StoreIDs),
          Channels: JSON.stringify(Channels),
          TrafficChannels: JSON.stringify(TrafficChannels),
          HasDisputed: JSON.stringify(HasDisputed),
          IsBlacklisted: JSON.stringify(IsBlacklisted),
          CustomerEmails: JSON.stringify(CustomerEmails),
          PhoneNumbers: JSON.stringify(PhoneNumbers),
          FirstName: JSON.stringify(FirstName),
          LastName: JSON.stringify(LastName),
          SubscriptionStatus: JSON.stringify(SubscriptionStatus),
          EndTime: EndTime ? EndTime.tz().format() : '',
          StartTime: StartTime ? StartTime.tz().format() : '',
          Page: String(Page),
          OrderBy: OrderBy,
          Descending: String(Descending),
          Limit: String(limit),
        },
        { replace: true },
      );
    }

    return payload;
  };

  const downloadCsvFile = async () => {
    // need to pass false to generate payload without setting search params
    setLoading(pre => pre + 1);
    const payload = generatePayload(false);
    payload.Page = 0;
    delete payload.Limit;
    const res = await globalCustomersService.downloadCustomerListCsv(payload);
    if (res?.status === 200) {
      downloadCsv(res.data, 'customers');
    }
    setLoading(pre => pre - 1);
  };

  const searchCustomerList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(pre => pre + 1);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload = generatePayload();
    const res = await globalCustomersService.searchCustomer({
      ...payload,
      Page: page,
      Limit: limit * totalValueForPagination,
    });
    setLoading(pre => pre - 1);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = res?.data?.Result.slice(0, limit);
          setCustomersList(data);
        }
        setPaginationCommon(
          res?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setpaginationData,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setCustomersList([]);
        setpaginationData(new Map());
      }
    } else {
      setCustomersList([]);
      setpaginationData(new Map());
    }
  };

  useEffect(() => {
    searchCustomerList();
  }, [filter, sideFormFilter, Page, limit, search, storeIds, timeZone]);

  useEffect(() => {
    Page !== 1 && searchCustomerList({ reset: false, isPrevious: false });
  }, [Page]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  return (
    <div className="customers_wrapper">
      <div className="customer">
        <div className="customer_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer className="Common_Table">
          <Table
            className="customers_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                <TableCell className="table_header_cell">
                  <div className="flex-row">
                    <Checkbox
                      checked={
                        customersList.length > 0 &&
                        selected.size === customersList.length
                      }
                      onChange={(_, checked) => selectAll(checked)}
                    />
                  </div>
                </TableCell>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {customersList.length > 0 ? (
                customersList.map((row, index) => {
                  const isItemSelected = !!selected.get(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      className="table_row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}>
                      <TableCell padding="checkbox" className="table_cell">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleRowChecked(row, isItemSelected)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_cell"
                            key={headCell.label}
                            component="th"
                            id={headCell.key}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : get(row, headCell.id)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="!py-20 !left-[50%] pointer-events-none">
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationData}
          loading={!!loading}
          setPaginationData={setpaginationData}
          searchApi={searchCustomerList}
          setData={setCustomersList}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
      <SideFilter
        headCellList={headCellList}
        setHeadCellList={setHeadCellList}
        selected={selected}
        resetSelected={() => setSelected(new Map())}
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        searchCustomerList={searchCustomerList}
        setLoading={setLoading}
        loading={loading}
        setCustomersList={setCustomersList}
        downloadCsvFile={downloadCsvFile}
      />
    </div>
  );
}
