import { FC } from 'react';
import { IUsersFilter } from 'interface/userInterface';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'shared-components';
import TextField from 'components/common/textField';
import 'components/admins/filter/FilterForm/style.scss';
import { defaultUsersValues } from 'components/admins';
import { MultipleInputTag } from 'components/common/multiInput';
import { adminOptions, emailRegex } from 'utils/constants';
import AutoCompleteSelect from 'components/common/selectAutoComplete';

interface IProps {
  sideFilter: IUsersFilter;
  filterSubmission: (_data: IUsersFilter) => void;
  setPage: (_page: number) => void;
  loading: number;
}

const FilterForm: FC<IProps> = ({
  sideFilter,
  filterSubmission,
  setPage,
  loading,
}) => {
  const methods = useForm<IUsersFilter>({
    defaultValues: sideFilter,
  });
  const { handleSubmit, reset, control, setError } = methods;

  const onSubmit = (data: IUsersFilter) => {
    filterSubmission(data);
    setPage(1);
  };
  return (
    <div className="user-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="Emails"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <MultipleInputTag
                tagsValue={value || []}
                label="Emails"
                isLowerCase={true}
                placeholder="Type Emails"
                onChange={onChange}
                validation={[
                  {
                    validationRule: emailRegex,
                    validationMessage: 'Enter a valid email address',
                  },
                ]}
                setError={(value: string) => {
                  setError('Emails', {
                    type: 'custom',
                    message: value,
                  });
                }}
                errorString={error?.message}
              />
            );
          }}
        />
        <Controller
          name="FirstName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="First Name"
                placeholder="Type First Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="LastName"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="Last Name"
                placeholder="Type Last Name"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <Controller
          name="SuperAdmin"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <AutoCompleteSelect
                label="Super Admin"
                placeholder="Select Super Admin"
                options={adminOptions}
                labelKey="label"
                onChange={val => {
                  onChange(val?.value);
                }}
                value={
                  adminOptions.find(option => option.value === value) || null
                }
                className="w-full dropdown-field"
              />
            );
          }}
        />
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit clear_button"
            onClick={() => {
              if (!loading) reset(defaultUsersValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
