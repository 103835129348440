import { ComponentConfig } from '@measured/puck';
import { useNavigate, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  IDropdownOptions,
  navigationPathField,
} from '../../../../page-builder/puck/reusable-props/navigation-path-field';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../../components/common/tooltip';
import showComponent, {
  showComponentProps,
} from '../../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../../utils/helper';
import { ReduxSelectorProps } from '../../../services/constants';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../../puck/reusable-props/justify-content';
import { marginFields, MarginProps } from '../../../puck/reusable-props/margin';
import {
  paddingFields,
  PaddingProps,
} from '../../../puck/reusable-props/padding';
import TextCustomInput from '../../../puck/reusable-props/TextCustomInput';
import { CustomizeProps } from '../../../interface/commonInterface';
import { useState } from 'react';
import ColorSelector from '../../../puck/reusable-props/color-selector';
import { widthField, WidthProps } from '../../../puck/reusable-props/width';
import { heightField, HeightProps } from '../../../puck/reusable-props/height';
import {
  borderRadiusField,
  BorderRadiusProps,
} from '../../../puck/reusable-props/border-radius';

export type ButtonSectionProps = {
  nextPagePath: IDropdownOptions;
  CustomizeFonts: CustomizeProps;
  background: {
    backgroundColor: string;
    hoverColor: string;
  };
} & showComponentProps &
  MarginProps &
  PaddingProps &
  JustifyContentProps &
  WidthProps &
  HeightProps &
  BorderRadiusProps;

export const ButtonComponent = ({
  useAppSelector,
  baseService,
}: ReduxSelectorProps & {
  baseService: AxiosInstance;
}): ComponentConfig<ButtonSectionProps> => {
  return {
    label: (
      <CustomTooltip title="Button">
        <span>Button</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...navigationPathField({
        baseService,
        useAppSelector,
        isShopifyRadio: true,
      }),
      CustomizeFonts: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              showHover={false}
              title="Button Text"
            />
          );
        },
      },
      background: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => {
          const [isOpen, setIsOpen] = useState(false);
          const toggleAccordion = () => {
            setIsOpen(!isOpen);
          };

          return (
            <>
              <div
                className="flex justify-between font-extrabold cursor-pointer  bg-slate-100 rounded-md pagebuilder-theme"
                onClick={toggleAccordion}>
                <h3>Customize Bg Color</h3>
                {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
              <div className="flex flex-col gap-1  mt-3 items-center">
                {isOpen && (
                  <div className="flex flex-col gap-4 w-full items-center border rounded p-2 pb-4">
                    <ColorSelector
                      value={value?.backgroundColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          backgroundColor: val,
                        });
                      }}
                      label="Background color"
                      tooltip="backgroundColor"
                    />
                    <ColorSelector
                      value={value?.hoverColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          hoverColor: val,
                        });
                      }}
                      label="Hover color"
                      tooltip="hoverColor"
                    />
                  </div>
                )}
              </div>
            </>
          );
        },
      },
      ...marginFields,
      ...paddingFields,
      ...JustifyContentField,
      ...heightField,
      ...widthField,
      ...borderRadiusField,
    },
    defaultProps: {
      borderRadius: 'rounded-md',
      height: 'full',
      width: 'w-full',
      background: {
        backgroundColor: '#ffffff',
        hoverColor: '#ffffff',
      },
      nextPagePath: { label: '', value: '', isShopify: true },
      showComponent: ['desktop', 'tablet', 'mobile'],
      CustomizeFonts: {
        fontSize: '12',
        fontWeight: 'font-medium',
        textColor: '#000000 ',
        hoverColor: '#000000',
        text: 'Button',
      },
      justifyContent: 'justify-start',
      margin: {
        desktop: {
          top: 'mt-4',
          bottom: 'mb-4',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-1',
          bottom: 'max-md:mb-1',
          right: 'max-md:mr-1',
          left: 'max-md:ml-1',
        },
        mobile: {
          top: 'max-sm:mt-1',
          bottom: 'max-sm:mb-1',
          right: 'max-sm:mr-1',
          left: 'max-sm:ml-1',
        },
      },
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
    },
    render: ({
      nextPagePath,
      margin,
      justifyContent,
      padding,
      showComponent,
      CustomizeFonts,
      background,
      borderRadius,
      height,
      width,
    }) => {
      const navigate = useNavigate();
      const { pathname } = useLocation();
      const handleClick = (): void => {
        if (nextPagePath?.isShopify) {
          window.open(nextPagePath?.value, '_blank');
        } else navigate(pathname + nextPagePath?.value);
      };
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`flex  ${justifyContent} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <button
                className={`flex  ${height} ${width} ${justifyContent} 
           ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}  ${CustomizeFonts?.fontSize} ${CustomizeFonts?.fontWeight} ${borderRadius}`}
                style={{
                  backgroundColor: background?.backgroundColor,
                  color: CustomizeFonts?.textColor,
                  fontFamily: CustomizeFonts?.fontFamily,
                  transition: 'background-color 0.3s',
                  fontSize: `${CustomizeFonts?.fontSize}px`,
                }}
                onMouseOver={e =>
                  (e.currentTarget.style.backgroundColor =
                    background?.hoverColor)
                }
                onMouseOut={e =>
                  (e.currentTarget.style.backgroundColor =
                    background?.backgroundColor)
                }
                onClick={handleClick}>
                {CustomizeFonts?.text}
              </button>
            </div>
          )}
        </>
      );
    },
  };
};
