import { ReactNode, useEffect, useState } from 'react';
import 'components/rentention/style.scss';
import utc from 'dayjs/plugin/utc';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import CustomDatePicker from 'components/common/customDatePicker';
import rententionService from 'services/retentionService';
import {
  IReBilling,
  IRetentionFilter,
  IRetention,
} from 'interface/notesInterface';
import { get } from 'lodash';
import { IReBillingFilterBody } from 'interface/retentioninterface';
import dayjs from 'dayjs';
import { Loader } from 'components/common/loader';
import { useSearchParams } from 'react-router-dom';
import { demicalFormatter, percentage, UsDollarFormatter } from 'utils/helper';
import { useAppSelector } from 'hooks/reduxHooks';
import { ControlledRadioButton } from 'components/common/radioButton';
import ProductsSearch from 'components/store/storeDetails/shopify/shopifyDetails/postUpsells/productsSearch';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { IProductBatch, IVarient } from 'interface/membershipOffersInterface';
import membershipOfferService from 'services/membershipOfferService';
import MerchantSelect from 'components/common/MerchantSelect';
import { IDefaultProps } from 'interface/commonInterface';

dayjs.extend(utc);

interface IRetentionList {
  Cycle: number;
  Total: number;
  TotalApproved: number;
  Amount: number;
  TotalCancelledBeforeBilling: number;
  Pending: number;
  Attempted: number;
}
export interface HeadCell {
  id: string;
  label: string;
  className?: string;
  cellRender?: (_row: IRetentionList) => ReactNode;
  getWidth?: (_row: IRetentionList) => string;
  hide?: boolean;
  hasPercentage?: boolean;
  valueGetter?: (_row: IRetention) => string | number;
}

const retentionDate = [
  { label: 'Charge Date', value: 'chargeDate' },
  { label: 'Acquisition Date', value: 'acquisitionDate' },
];

export default function RetentionReport() {
  const headCells_rebill: readonly HeadCell[] = [
    {
      id: 'Cycle',
      label: 'Cycle Name',
      hide: false,
      cellRender: (row: IRetentionList) => {
        return `Cycle ${row?.Cycle}`;
      },
    },
    {
      id: 'Total',
      label: 'Total',
      hide: false,
      cellRender: (row: IRetentionList) => {
        return <span>{row?.Total}</span>;
      },
    },
    {
      id: 'TotalCancelledBeforeBilling',
      label: 'Cancelled Before Billing',
      hide: false,
      cellRender: (row: IRetentionList) => {
        return <span>{`${row.TotalCancelledBeforeBilling}`}</span>;
      },
    },
    {
      id: 'Pendings',
      label: 'Pending',
      hide: false,
      hasPercentage: false,
      cellRender: (row: IRetentionList) => {
        const pendingPercentage = (100 * row.Pending) / row.Total;
        return (
          <span>{`${row.Pending} (${pendingPercentage % 1 !== 0 ? parseFloat(pendingPercentage.toFixed(1)) : pendingPercentage}%)`}</span>
        );
      },
    },
    {
      id: 'Attempted',
      label: 'Attempted',
      hide: false,
      hasPercentage: true,
      className: 'attempted',
      getWidth: (row: IRetentionList) => {
        return String((row.Attempted / row.Total) * 100);
      },
      cellRender: (row: IRetentionList) => {
        return (
          <div>
            <span>{`${row.Attempted} `}</span>
          </div>
        );
      },
    },
    {
      id: 'TotalApproved',
      label: 'Approved',
      hide: false,
      hasPercentage: true,
      getWidth: (row: IRetentionList) => {
        return String((row.TotalApproved / row.Attempted) * 100);
      },
      cellRender: (row: IRetentionList) => {
        return (
          <div>
            <span>{`${row.TotalApproved} `}</span>
          </div>
        );
      },
      className: 'approved',
    },
    {
      id: 'Revenue',
      label: 'Revenue',
      hide: false,
      cellRender: (row: IRetentionList) => {
        const revenuePrecentage = row?.Amount;
        return (
          <div>
            <span>{`$${demicalFormatter(UsDollarFormatter(Number(revenuePrecentage)))}`}</span>
          </div>
        );
      },
    },
    {
      id: 'Overallretention',
      label: 'Overall retention',
      cellRender: (row: IRetentionList) => {
        return (
          <div>
            <span>{`${percentage(row?.TotalApproved, row?.Total - row?.Pending, true)}%`}</span>
          </div>
        );
      },
    },
  ];
  const default_start_time = null;
  const default_end_time = null;
  const [retentionList, setRententionList] = useState<IRetentionList[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm<IRetentionFilter>({
    defaultValues: {
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : default_start_time,
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : default_end_time,
      RadioAcquisition:
        searchParams.get('RadioAcquisitionKey') === 'acquisitionDate'
          ? 'acquisitionDate'
          : 'chargeDate',
      ProductID: {
        label: '',
        value: '',
      },
      VariantID: {
        label: '',
        value: '',
      },
    },
  });
  const { watch, setValue } = methods;
  const {
    EndTime,
    StartTime,
    RadioAcquisition,
    ProductID,
    VariantID,
    ExternalProcessorID,
  } = watch();
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [productsVarientOptions, setProductsVarientOptions] = useState<
    IDefaultProps[] | null
  >(null);

  const getReBilling = async () => {
    let payload: IReBillingFilterBody = {};
    const date = {
      StartTime: StartTime ? StartTime.tz().startOf('day').format() : null,
      EndTime: EndTime
        ? EndTime.tz().add(1, 'day').startOf('day').format()
        : null,
    };

    if (RadioAcquisition === 'acquisitionDate') {
      payload = {
        AcquisitionStartTime: date?.StartTime,
        AcquisitionEndTime: date?.EndTime,
      };
    } else {
      payload = {
        ...date,
      };
    }
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds?.map(store => store.ID);
    }
    if (storefrontIds?.length > 0) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (ExternalProcessorID?.value) {
      payload.ExternalProcessorID = ExternalProcessorID?.value;
    }
    setSearchParams(
      {
        EndTime: EndTime ? dayjs(EndTime).tz().format() : '',
        StartTime: StartTime ? dayjs(StartTime).tz().format() : '',
        RadioAcquisitionKey: RadioAcquisition ?? '',
        ProductID: JSON.stringify(ProductID) ?? '',
        VariantID: JSON.stringify(VariantID) ?? '',
        ExternalProcessorID: JSON.stringify(watch('ExternalProcessorID')) ?? '',
      },
      { replace: true },
    );
    setLoading(true);
    if (ProductID?.value) {
      payload.ProductID = ProductID?.value;
    }
    if (VariantID?.value) {
      payload.VariantID = VariantID.value;
    }
    const [responseOfReBilling] = await Promise.all([
      rententionService.getReBillingByProduct(payload),
    ]);

    if (responseOfReBilling.status === 200) {
      getRetention(responseOfReBilling?.data?.Result);
    } else {
      setRententionList([]);
    }
    setLoading(false);
  };
  const getRetention = (billing: IReBilling[]) => {
    const retentionDats = billing
      ?.map((item: IReBilling) => ({
        Cycle: item.Cycle ? item.Cycle : 0,
        Amount: item.TotalAmount ? item.TotalAmount : 0,
        Total: item.Customers ? item.Customers : 0,
        TotalApproved: item.TotalApproved ? item.TotalApproved : 0,
        Attempted: item.Attempted ? item.Attempted : 0,
        Pending: item.Pending ? item.Pending : 0,
        TotalCancelledBeforeBilling: item.TotalCanceledBefore
          ? item.TotalCanceledBefore
          : 0,
      }))
      .sort((a, b) => {
        if (a.Cycle !== b.Cycle) {
          return a.Cycle - b.Cycle;
        }
        return 0;
      });

    setRententionList(retentionDats);
  };

  const shopId = storeIds?.[0]?.storeFronts?.[0]?.ShopID;
  const storeId = storeIds?.[0]?.ID;
  const isStoreAndStorefrontSelected = !(
    storeIds?.length > 0 && storeIds[0]?.storeFronts?.length
  );

  if (!storeId || !shopId) {
    if (ProductID?.value || VariantID?.value) {
      setProductsVarientOptions(null);
      setValue('ProductID', {
        label: '',
        value: '',
      });
      setValue('VariantID', {
        label: '',
        value: '',
      });
    }
  }

  const getProductVarient = async () => {
    if (!ProductID?.value || !shopId || !storeId) {
      setProductsVarientOptions(null);
      return;
    }
    const payload: IProductBatch = {
      ShopID: shopId,
      StoreID: storeId,
      ProductIDs: [Number(ProductID?.value?.split('/').pop()) || 0],
    };

    const res = await membershipOfferService.getProductWithVariants(payload);
    if (res?.status === 200) {
      const varientOptions: IDefaultProps[] = [];
      res?.data?.Result[0]?.Variants.forEach((varient: IVarient) => {
        varientOptions.push({
          label: varient.Title || 'No Title',
          value: varient.ID,
        });
      });
      setProductsVarientOptions(varientOptions);
    } else {
      setProductsVarientOptions(null);
    }
  };
  useEffect(() => {
    getReBilling();
  }, [
    EndTime,
    StartTime,
    storeIds,
    RadioAcquisition,
    timeZone,
    ProductID,
    VariantID,
    ExternalProcessorID,
  ]);
  return (
    <div className="rention-report">
      <div className="retention-filters">
        <form className="filter-selection-form">
          <FormProvider {...methods}>
            <div className="flex gap-5 flex-wrap">
              <div className="">
                <div className="common_label_text mb-1">Date filter by</div>
                <ControlledRadioButton
                  name="RadioAcquisition"
                  onChange={event => {
                    if (
                      event.target.value === 'acquisitionDate' &&
                      ((StartTime && StartTime > dayjs().tz()) ||
                        (EndTime && EndTime > dayjs().tz()))
                    ) {
                      setValue('StartTime', null);
                      setValue('EndTime', null);
                    }
                    setValue(
                      'RadioAcquisition',
                      event.target.value as 'chargeDate' | 'acquisitionDate',
                    );
                  }}
                  value={RadioAcquisition}
                  label=""
                  className="filter_form_input"
                  options={retentionDate}
                />
              </div>
              <div className="">
                <div className="common_label_text">Date Range</div>
                <CustomDatePicker
                  selectedRange={{
                    startDate: watch('StartTime'),
                    endDate: watch('EndTime'),
                  }}
                  key={RadioAcquisition}
                  dateValues={range => {
                    if (range.startDate && range.endDate) {
                      setValue('StartTime', range.startDate);
                      setValue('EndTime', range.endDate);
                    } else {
                      setValue('StartTime', null);
                      setValue('EndTime', null);
                    }
                  }}
                  maxDate={
                    RadioAcquisition === 'acquisitionDate'
                      ? dayjs().tz().toDate()
                      : null
                  }
                />
              </div>
              <div className="w-52">
                <Controller
                  name="ExternalProcessorID"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <MerchantSelect
                        label="External Processor ID"
                        placeholder="Type External Processor ID"
                        onChange={val => {
                          onChange(val || null);
                        }}
                        value={value}
                      />
                    );
                  }}
                />
              </div>
              <Tooltip
                title={`${isStoreAndStorefrontSelected ? 'Store and storefront selection required' : ''}`}
                arrow>
                <div
                  className={`${isStoreAndStorefrontSelected && 'block-product-varient'} w-52 `}>
                  <Controller
                    name="ProductID"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <ProductsSearch
                          errorMessages={error?.message}
                          disabled={isStoreAndStorefrontSelected}
                          onChange={val => {
                            setValue('VariantID', {
                              label: '',
                              value: '',
                            });
                            onChange(val);
                          }}
                          shopId={shopId}
                          storeId={shopId}
                          value={value?.value}
                        />
                      );
                    }}
                  />
                </div>
              </Tooltip>
              {!isStoreAndStorefrontSelected && (
                <Tooltip
                  title={`${!ProductID?.value ? 'Product selection required' : ''}`}
                  arrow>
                  <div
                    className={`${!ProductID?.value && 'block-product-varient'} w-52 `}>
                    <Controller
                      name="VariantID"
                      render={({ field: { value, onChange } }) => {
                        return (
                          <AutoCompleteSelect
                            loading={loading}
                            options={
                              !loading
                                ? productsVarientOptions || [
                                    {
                                      label: '',
                                      value: '',
                                    },
                                  ]
                                : []
                            }
                            labelKey="label"
                            onChange={val => {
                              onChange(val);
                            }}
                            onOpen={() => {
                              getProductVarient();
                            }}
                            placeholder="Select Varient"
                            value={
                              productsVarientOptions?.find(
                                type => type?.value === value?.value,
                              ) || null
                            }
                            className="w-full dropdown-field"
                            label="Varients"
                            disabled={!ProductID?.value}
                          />
                        );
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </FormProvider>
        </form>
      </div>

      <div className="rention-first-table">
        <span className="heading">Rebill Summary </span>

        <div className="retention">
          <TableContainer className="Common_Table rentention_page_table">
            <Table
              className="rention_table"
              aria-labelledby="tableTitle"
              stickyHeader>
              <TableHead className="table_header">
                <TableRow>
                  {headCells_rebill?.map(headCell => {
                    if (headCell?.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        align="center"
                        className="table_header_cell header_text"
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {retentionList?.length > 0 ? (
                  retentionList.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        className={`table_row ${row}`}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}>
                        {headCells_rebill.map(headCell => {
                          if (headCell?.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell percentage_column"
                              align="center"
                              key={headCell?.label}
                              component="th"
                              id={headCell?.label}
                              scope="row">
                              <div
                                className={`percent-width ${headCell.className?.toLocaleLowerCase()}`}
                                style={
                                  headCell?.hasPercentage
                                    ? {
                                        width: `${headCell?.getWidth ? headCell?.getWidth(row) : null}%`,
                                      }
                                    : {}
                                }></div>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="!py-20 !left-[50%] pointer-events-none">
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
}
