import { AxiosError } from 'axios';
import { baseService } from 'services';
import { IReBilling, IRetentionReportLifetime } from 'interface/notesInterface';
import {
  commonError,
  getRetentionByProductEP,
  getRetentionCancellationEp,
  getRetentionEP,
  getRetentionReportLifeTimeEP,
} from 'services/constants';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  IReBillingByProduct,
  IReBillingFilterBody,
  IRetentionLifetimeBody,
} from 'interface/retentioninterface';

class RetentionMockService {
  getReBilling = async () => {
    try {
      return await new Promise<ISuccessResponse<IReBilling[]>>(resolve => {
        setTimeout(() => {
          resolve({
            data: [],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getReBillingByProduct = async () => {
    try {
      return await new Promise<ISuccessResponse<IReBilling[]>>(resolve => {
        setTimeout(() => {
          resolve({
            data: [],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getRetentionReportLifetime = async (): Promise<
    ISuccessResponse<IRetentionReportLifetime[]> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<
        ISuccessResponse<{ Result: IRetentionReportLifetime[] }>
      >(resolve => {
        setTimeout(() => {
          resolve({
            data: { Result: [] },
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCancelling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post<IReBilling[]>(
        getRetentionCancellationEp(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class RetentionService {
  NotesMockService = new RetentionMockService();
  getReBilling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post<IReBilling[]>(
        getRetentionEP(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getReBillingByProduct = async (payload: IReBillingByProduct) => {
    try {
      const response = await baseService.post<IReBilling[]>(
        getRetentionByProductEP,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getRetentionReportLifetime = async (
    payload: IRetentionLifetimeBody,
  ): Promise<
    ISuccessResponse<IRetentionReportLifetime[]> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<{
        Result: IRetentionReportLifetime[];
      }>(getRetentionReportLifeTimeEP, payload);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCancelling = async (payload: IReBillingFilterBody) => {
    try {
      const response = await baseService.post(
        getRetentionCancellationEp(),
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
const globalnotesService: RetentionService | RetentionMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new RetentionMockService()
    : new RetentionService();

export default globalnotesService;
