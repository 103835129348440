import {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { Tooltip } from '@mui/material';

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  labelKey?: string;
  valueKey?: string;
  defaultOption?: string;
  error?: string;
  tooltipTitle?: string;
  InputIcon?: React.ComponentType;
}

export const TextField = forwardRef(function TextFieldInternal(
  {
    label,
    className,
    error,
    InputIcon,
    tooltipTitle,
    ...rest
  }: ITextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <div className={`${className}`}>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className=" relative">
        <input
          type="text"
          ref={ref}
          onKeyDown={e => e.stopPropagation()}
          className="block w-full h-[42px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          {...rest}
        />
        {InputIcon && (
          <Tooltip title={tooltipTitle} arrow>
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <InputIcon />
            </span>
          </Tooltip>
        )}
      </div>
      {error && (
        <div className="error-message" style={{ color: 'var(--error-color)' }}>
          {error}
        </div>
      )}
    </div>
  );
});

interface ITextFieldControlProps extends ITextFieldProps {
  name: string;
  defaultValue?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  valueFormatter?: (_value: string) => string;
  onChangeHandler?: (_e: ChangeEvent<HTMLInputElement>) => string;
}
export const TextFieldControl = ({
  name,
  rules,
  valueFormatter,
  onChangeHandler,
  defaultValue,
  ...rest
}: ITextFieldControlProps) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ...restField },
        fieldState: { error },
      }) => {
        return (
          <TextField
            {...rest}
            value={valueFormatter ? valueFormatter(value) : value}
            onChange={e =>
              onChange(onChangeHandler ? onChangeHandler(e) : e.target.value)
            }
            {...restField}
            error={error?.message}
          />
        );
      }}
      rules={rules}
    />
  );
};
