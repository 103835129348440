import AutoCompleteSelect from 'components/common/selectAutoComplete';
import React, { useCallback, useRef, useState } from 'react';
import {
  IMerchantAccountBody,
  IMerchantResponse,
} from 'interface/paymentGatewayInterface';
import globalPaymentGateWayService from 'services/paymentGatewayService';
import { IDefaultProps } from 'interface/commonInterface';

export const getMerchantsLabels = (
  merchants: IMerchantResponse[],
): IDefaultProps[] => {
  return merchants?.map(merchant => ({
    label: merchant?.Name,
    value: merchant?.MerchantID,
  }));
};

const defaultValues = {
  Descending: false,
  OrderBy: 'Name',
  Limit: 25,
  Page: 0,
};

interface IProps {
  label?: string;
  placeholder?: string;
  className?: string;
  onChange: (_: IDefaultProps | null) => void;
  value: IDefaultProps | null;
}

export default function MerchantSelect({
  label = 'External Processor ID',
  placeholder = 'Select External Processor ID',
  className,
  onChange,
  value,
}: IProps) {
  const position = useRef<number>();
  const [loading, setLoading] = useState(false);
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState(false);
  const [merchantDeatails, setMerchantDeatils] = useState<IDefaultProps[]>([]);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const getProcessorAccounts = async (payload: IMerchantAccountBody) => {
    setLoading(true);
    const res = await globalPaymentGateWayService.getProcessorAccounts(payload);
    setLoading(false);
    const result = res?.data?.Result;
    if (res?.status === 200 && result) {
      setIsNextAPIDisabled(result?.length < payload.Limit);
      if (payload.Page === 0) {
        setMerchantDeatils(getMerchantsLabels(res.data.Result));
      } else {
        setMerchantDeatils(prevData => [
          ...prevData,
          ...getMerchantsLabels(res.data.Result),
        ]);
      }
    } else {
      setIsNextAPIDisabled(false);
    }
  };

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isNextAPIDisabled) {
          const payload = { ...defaultValues, Page: defaultValues.Page + 1 };
          getProcessorAccounts(payload);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );

  const resetStores = () => {
    getProcessorAccounts(defaultValues);
    setMerchantDeatils([]);
  };

  const setRef = (value: number) => {
    position.current = value;
  };

  return (
    <AutoCompleteSelect
      lastElementRef={lastElementRef}
      position={position.current}
      setPostionRef={value => setRef(value)}
      loading={loading}
      options={!loading ? merchantDeatails : []}
      labelKey="label"
      onChange={val => {
        onChange(val);
      }}
      onOpen={() => {
        if (merchantDeatails?.length === 0) resetStores();
      }}
      placeholder={placeholder}
      value={value ? value : null}
      className={`w-full dropdown-field ${className}`}
      label={label}
    />
  );
}
