import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { showNotification } from 'helper/common/commonFunctions';
import { Select } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import globalStoreFrontService from 'services/storeFrontService';
import {
  IStoreFrontEventResponse,
  IStoreFrontEventUpdate,
} from 'interface/storeInterface';
import { resourceTypes, YesNoOptionsWithTrueFalse } from 'utils/constants';
import { INotes } from 'interface/notesInterface';
import './style.scss';
import notesService from 'services/notesService';
interface IProps {
  handleClose: () => void;
  payload: IStoreFrontEventResponse;
  onSuccess: () => void;
}

interface IStoreEvents {
  Resolved: boolean;
  tag: string[];
  notes: string;
}
const UpdateStoreFrontEvent: FC<IProps> = ({
  handleClose,
  payload,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IStoreEvents>({
    defaultValues: {
      Resolved: false,
      tag: payload.Tags || [],
      notes: payload?.Note || '',
    },
  });

  const updateStoreFrontEvent = async (data: IStoreEvents) => {
    const NotesPayload: INotes = {
      Text: data.notes,
      StoreID: payload.Store.ID,
      ResourceID: payload.ID,
      ResourceType: resourceTypes?.events,
    };

    const storeEventPayload: IStoreFrontEventUpdate = {
      Tags: data.tag,
      Resolved: data.Resolved,
      Version: payload.Version,
      ID: payload.ID,
    };
    setLoading(true);
    const [notesResponse, eventsResponse] = await Promise.all([
      globalStoreFrontService.updateStoreFrontEvents(storeEventPayload),
      notesService.postNotes(NotesPayload),
    ]);
    setLoading(false);
    if (notesResponse?.status === 200 && eventsResponse?.status === 200) {
      showNotification('success', 'Customer Updated Successfully');
      onSuccess();
    }
    handleClose();
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            handleClose();
          },
          disabled: loading,
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'storeEvent',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      className="flex-container"
      heading={'Update'}>
      <div className="popup-section">
        <form
          id="storeEvent"
          onSubmit={handleSubmit(updateStoreFrontEvent)}
          className="order-detail_refund">
          <div className="fields grid gap-1.5">
            <Controller
              name="Resolved"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    onChange={onChange}
                    value={value}
                    label="Resolved"
                    options={YesNoOptionsWithTrueFalse}
                    placeholder="other"
                  />
                );
              }}
            />
            <Controller
              name="notes"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <div>
                      <div className="store-event-notes">
                        <div className="label">Notes</div>
                        <div className="addnotes-container">
                          <textarea
                            className="addnotes-box"
                            placeholder="Add Note "
                            value={value}
                            onChange={e => onChange(e.target.value)}></textarea>
                        </div>
                      </div>
                      {error ? (
                        <p className="error_message">{error.message}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              }}
            />
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default UpdateStoreFrontEvent;
