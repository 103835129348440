import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { debounce } from '@mui/material';
interface SliderProps {
  onChange: (_value: number | number[]) => void;
  value: number;
}
export default function DiscreteSlider({ onChange, value }: SliderProps) {
  const [sliderValue, setSliderValue] = React.useState(value || 0);
  const handleDebounce = debounce(value => {
    onChange(value);
  }, 1000);

  return (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}>
      <Box sx={{ width: 220 }}>
        <Slider
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={100}
          value={sliderValue}
          slots={{ mark: 'span' }}
          sx={{ color: '#ff5acd', marginLeft: '20px' }}
          onChange={(e, value) => {
            e.preventDefault();
            e.stopPropagation();
            setSliderValue(value as number);
            handleDebounce(value);
          }}
        />
      </Box>
    </div>
  );
}
