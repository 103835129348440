import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { get } from 'lodash';
import AddCheckoutFlow from './AddCheckoutFlow';
import { Select } from 'shared-components';
import { Button } from 'shared-components';
import { ReactComponent as LinkIcon } from 'assets/icons/link-icon.svg';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import globalCheckoutFlowService from 'services/checkoutFlowService';
import { ICheckoutData, ICheckoutFlow } from 'interface/checkoutFlow';
import { ICheckoutPage } from 'interface/checkoutPagesInterface';
import globalPageService from 'services/pageService';
import { showNotification } from 'helper/common/commonFunctions';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { YesNoOptions } from 'utils/constants';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ICheckoutData, _index: number, _id: string) => ReactNode;
  hide?: boolean;
}

export interface IFormProps {
  ID: string;
  Name: string;
  Weight: number;
  VIPProductsOnly: boolean;
  Page: string;
}

export interface ICheckoutProps {
  storeId: string;
  shopifyId: string;
}

const defaultOptions: ICheckoutData[] = [];

export default function CheckoutFlow({ storeId, shopifyId }: ICheckoutProps) {
  const [pagesDetails, setPagesDetails] = useState<
    {
      label: string;
      value: string;
    }[]
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const defaultStoreFront: ICheckoutData = {
    Name: '',
    PageID: '',
    Tags: [],
    VIPProductsOnly: false,
    Weight: 0,
    ID: '',
    ChannelID: '',
    Version: 0,
  };
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      checkout: defaultOptions,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'checkout',
  });

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      hide: false,
      cellRender: (row, index) => {
        return (
          <Controller
            name={`checkout.${index}.Name`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  {...rest}
                  value={value}
                  errorString={error?.message}
                  className="filter_form_input"
                  fixedSize="md"
                  placeholder="Enter name"
                  ref={ref}
                />
              );
            }}
          />
        );
      },
    },
    {
      id: 'CheckoutURL',
      label: 'Traffic Split',
      hide: false,
      cellRender: (row, index) => {
        return (
          <Controller
            name={`checkout.${index}.Weight`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, onChange, value, ...rest },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  {...rest}
                  value={value}
                  errorString={error?.message}
                  className="filter_form_input"
                  fixedSize="md"
                  placeholder="Enter Split"
                  ref={ref}
                  onChange={e => {
                    let newValue = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers (0-9)
                    if (newValue !== '' && parseInt(newValue, 10) > 100) {
                      newValue = '100'; // Limit to a maximum value of 100
                    }
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
        );
      },
    },
    {
      id: 'StoreDescription',
      label: 'Membership Only',
      hide: false,
      cellRender: (row, index) => {
        return (
          <Controller
            control={control}
            name={`checkout.${index}.VIPProductsOnly`}
            render={({ field }) => (
              <Select
                value={field.value ? 'yes' : 'no'}
                placeholder="Select Page"
                options={YesNoOptions}
                onChange={value => {
                  field.onChange(value === 'yes' ? true : false);
                }}
              />
            )}
          />
        );
      },
    },
    {
      id: 'StoreDescription',
      label: 'Page',
      hide: false,
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            control={control}
            name={`checkout.${index}.PageID`}
            rules={{
              required: 'This field is required',
            }}
            render={({ field, fieldState: { error } }) => (
              <AutoCompleteSelect
                labelKey="label"
                disablePortal={false}
                options={pagesDetails?.length ? pagesDetails : []}
                value={
                  pagesDetails?.find(item => item?.value === field.value) ||
                  null
                }
                ref={field.ref}
                onChange={val => {
                  field.onChange(val?.value);
                }}
                placeholder="Select Page"
                error={error?.message}
              />
            )}
          />
        );
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: (row, index) => {
        return (
          <div className="flex gap-3 items-center justify-end">
            <button className="create_store" type="button">
              <LinkIcon />
            </button>
            <button
              className="create_store"
              type="button"
              onClick={() => {
                remove(index);
              }}>
              <DeleteIcon />
            </button>
          </div>
        );
      },
    },
  ];

  const handleFormSubmit = (data: { checkout: ICheckoutData[] }) => {
    setCheckoutFlowDetails(data.checkout);
  };

  const verifyWeight = (data: ICheckoutData[]) => {
    const weight = data?.reduce((pre, current) => {
      return pre + Number(current?.Weight);
    }, 0);
    return weight === 100;
  };

  const setCheckoutFlowDetails = async (data: ICheckoutData[]) => {
    if (!verifyWeight(data)) {
      showNotification('error', 'Traffic Split sum should be 100%');
      return;
    }
    const payload: ICheckoutFlow[] = data.map(item => {
      return {
        Name: item.Name,
        Weight: Number(item.Weight),
        PageID: item.PageID,
        VIPProductsOnly: item.VIPProductsOnly,
        Tags: [],
      };
    });
    setLoading(true);
    const res = await globalCheckoutFlowService.createCheckoutFlow({
      ChannelID: shopifyId,
      CheckoutFlows: payload,
    });
    if (res?.status === 200) {
      showNotification('success', 'Checkout flow added successfully');
    }
    setLoading(false);
  };

  const getCheckoutFlowDetails = async () => {
    setLoading(true);
    const res = await globalCheckoutFlowService.getCheckoutFlow({
      ChannelID: shopifyId,
      Descending: true,
      Limit: 100,
      OrderBy: 'Name',
      Page: 0,
    });
    if (res?.status === 200 && res?.data?.Result?.length) {
      reset({ checkout: res.data.Result });
    }
    setLoading(false);
  };

  const getPages = async () => {
    setLoading(true);
    const res = await globalPageService.searchPages({
      Descending: true,
      Limit: 100,
      OrderBy: 'Name',
      Page: 0,
      StoreID: storeId,
    });
    if (res?.status === 200 && res?.data?.Result?.length) {
      setPagesDetails(
        res.data.Result.map((item: ICheckoutPage) => ({
          label: item.Name,
          value: item.ID,
        })),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getCheckoutFlowDetails();
    getPages();
  }, []);
  return (
    <div className="checkout-flow">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="h-full">
          <TableContainer className="Common_Table settingsTable !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className={
                          headCell.label == 'Actions' ? '!text-right' : ''
                        }
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <>
                <TableBody className="table-body">
                  {fields?.length > 0 ? (
                    fields?.map((row, index) => {
                      return (
                        <TableRow
                          className="table-row"
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                className="table-cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row, index, row.id)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={15} className="!h-40">
                        <div className="no-data-row">No data found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={15}>
                      <button
                        className="sticky-button flex items-center"
                        type="button"
                        onClick={() => {
                          append(defaultStoreFront);
                        }}>
                        <AddIcon /> Add checkout flow
                      </button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            </Table>
          </TableContainer>
        </div>
        <div className="flex justify-end">
          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply Filter"
          />
        </div>
      </form>
      <Loader loading={loading} />
      {showPopup && (
        <AddCheckoutFlow
          pageOptions={pagesDetails?.length ? pagesDetails : []}
          handleClose={() => setShowPopup(val => !val)}
          action={data => {
            console.log(data);
          }}
        />
      )}
    </div>
  );
}
