import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import { IStoreFrontEventsFilter } from 'interface/storeInterface';

import {
  IDeleteMerchantAccount,
  IMerchantAccountBody,
  IMerchantResponse,
} from 'interface/paymentGatewayInterface';
import globalPaymentGateWayService from '../../../services/paymentGatewayService';
import AddMerchant from './AddMerchant';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import { formattedDate } from 'utils/helper';
import { showNotification } from 'helper/common/commonFunctions';
import { Tooltip } from '@mui/material';
import CommonTable from 'components/common/TableCommon';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IMerchantResponse) => ReactNode;
  hide?: boolean;
  key: string;
  showInSearch?: boolean;
  showSortIcon?: boolean;
}

export const defaultStoreFrontEventValues: IStoreFrontEventsFilter = {
  eventName: '',
  time: '',
  resolved: 'no',
  storeFilter: {
    StoreIDs: [],
  },
  tags: [],
  ipAddress: '',
  StartTime: null,
  EndTime: null,
};

interface IProps {
  storeID: string;
}

export default function MerchantAccount({ storeID }: IProps) {
  const limit = 25;
  const defaultValue: IMerchantResponse = {
    AcquiringBankName: '',
    DBAName: '',
    IssuedDate: '',
    MerchantID: '',
    Name: '',
    ProcessorName: '',
    ServiceProviderName: '',
    GatewayID: '',
    GatewayKind: '',
    ID: '',
    MCC: 0,
    StoreID: storeID,
    Version: 0,
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedData, setSelectedData] =
    useState<IMerchantResponse>(defaultValue);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [merchantDeatails, setMerchantDeatils] = useState<IMerchantResponse[]>(
    [],
  );
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      key: 'name',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row?.Name;
      },
    },
    {
      id: 'GatewayKind',
      label: 'Gateway Kind',
      key: 'gateway',
      hide: false,
      showSortIcon: true,
      cellRender: row => {
        return row?.GatewayKind;
      },
    },
    {
      id: 'IssuedDate',
      label: 'Issued Date',
      key: 'issuedDate',
      hide: false,
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.IssuedDate)} arrow>
            <span>{formattedDate(row?.IssuedDate, true)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'ProcessorName',
      label: 'Merchant Processor',
      key: 'merchantProcessor',
      hide: false,
      cellRender: row => {
        return row?.ProcessorName;
      },
    },
    {
      id: 'ServiceProviderName',
      label: 'Service Provider',
      key: 'service',
      hide: false,
      cellRender: row => {
        return row?.ServiceProviderName;
      },
    },
    {
      id: 'AcquiringBankName',
      label: 'Acquiring Bank',
      key: 'acqiringBank',
      hide: false,
      cellRender: row => {
        return row?.AcquiringBankName;
      },
    },
    {
      id: 'DBAName',
      label: 'DBA',
      key: 'dba',
      hide: false,
      cellRender: row => {
        return row?.DBAName;
      },
    },
    {
      id: 'MerchantID',
      label: 'Merchant ID',
      key: 'merchantId',
      hide: false,
      cellRender: row => {
        return row?.MerchantID;
      },
    },
    {
      id: 'MCC',
      label: 'MCC',
      key: 'mcc',
      hide: false,
      cellRender: row => {
        return row?.MCC ?? '-';
      },
    },
    {
      id: 'Actions',
      label: 'Actions',
      key: 'action',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex gap-3">
            <CreateIcon
              className="w-5 h-5"
              onClick={() => {
                setShowPopup(true);
                setSelectedData(row);
              }}
            />
            <DeleteIcon
              className="w-5.5 h-5.5"
              onClick={() => {
                setIsDelete(true);
                setSelectedData(row);
              }}
            />{' '}
          </div>
        );
      },
    },
  ];

  const deleteMerchantProcessor = async () => {
    const payload: IDeleteMerchantAccount = {
      ProcessorAccountIDs: [selectedData?.ID ?? ''],
      StoreID: selectedData.StoreID,
    };
    const res =
      await globalPaymentGateWayService.deleteProcessorAccounts(payload);
    if (res.status === 200) {
      showNotification('success', 'Merchant Account Deleted Successfully');
      if (page === 1) {
        searchStoreFrontEvents();
      } else setPage(1);
      setIsDelete(false);
    }
  };

  const searchStoreFrontEvents = async () => {
    try {
      const payload: IMerchantAccountBody = {
        Descending: false,
        OrderBy: 'Name',
        Limit: limit,
        Page: page - 1,
        StoreIDs: [storeID],
      };

      setLoading(true);
      const res =
        await globalPaymentGateWayService.getProcessorAccounts(payload);
      if (res?.status === 200) {
        if (res?.data?.Result?.length > 0) {
          setIsNextAPIDisabled(limit > res?.data?.Result?.length);
          if (page !== 1) {
            setMerchantDeatils(prev => [...prev, ...res.data.Result]);
            return;
          }
          setMerchantDeatils(res.data.Result);
        }
      } else {
        setMerchantDeatils([]);
      }
    } catch (error) {
      setMerchantDeatils([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchStoreFrontEvents();
  }, [page]);

  return (
    <>
      <div className="proccessor h-full">
        <CommonTable<IMerchantResponse>
          addLabelName="Add Processor"
          isNextAPIDisabled={isNextAPIDisabled}
          headCellList={headCellList}
          data={merchantDeatails}
          onEndReached={() => {
            setPage(prev => prev + 1);
          }}
          onAdd={() => {
            setShowPopup(true);
            setSelectedData(defaultValue);
          }}
        />
      </div>
      <Loader loading={loading} />
      {showPopup && (
        <AddMerchant
          defaultStoreValues={selectedData}
          handleClose={() => setShowPopup(false)}
          onSuccess={() => {
            if (page === 1) {
              searchStoreFrontEvents();
            } else setPage(1);
          }}
        />
      )}
      <ConfirmationDialog
        open={isDelete}
        handleClose={() => setIsDelete(false)}
        message="delete merchant processor"
        onSubmit={() => deleteMerchantProcessor()}
      />
    </>
  );
}
