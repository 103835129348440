import { ComponentConfig, Field, FieldLabel } from '@measured/puck';
import { useCallback, useState } from 'react';
import CustomTooltip from '../../../components/common/tooltip';
import './style.scss';
import checkoutService from '../../api/checkoutService';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Switch, Tooltip } from '@mui/material';
import { AddItemPayload } from '../../interface/apiServiceInterface';
import { setCartData, setIsCartLoading } from '../../redux/slices/cartSlice';
import { setAvailableShippingMethods } from '../../redux/slices/checkoutSlice';
import {
  CheckoutUpdateItemsPayload,
  LineItem,
  ShippingMethod,
  V2ShippingMethod,
} from '../../interface/checkoutInterface';
import { setCheckoutErrorMessage } from '../../redux/slices/commonSlice';
import { AxiosInstance } from 'axios';
import InfoIcon from '@mui/icons-material/Info';
import { CartProductsProps } from '../../puck/reusable-props/ProductCarts';
import pageBuilderService from '../../api/pageBuilderService';
import { IProductVariant, IVariant } from '../../interface/pageInterface';
import { Path } from '../../utils/constants';
import CustomCheckbox from '../../puck/reusable-props/ProductCarts';
import ColorSelector from '../../puck/reusable-props/color-selector';
import Select from '../../../components/common/select';
import {
  borderRadiusOptions,
  fontFamilies,
} from '../../puck/reusable-props/options';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select as SelectForm } from '../form-fields/Select';
import DiscreteSlider from '../../../components/common/slider/slider';
import ToggleButton from '../../../components/common/toggleSwitchCustom';

interface ICheckBoxComponent {
  title: string;
  productTextColor: string;
  offerTextColor: string;
  offerRadius: string;
  offerTextFamily: string;
  offerBackgroundColor: string;
  amountTextColor: string;
  productTextFamily: string;
  isToggle?: boolean;
  isChecked?: boolean;
  isShowProductName?: boolean;
  isShowProductOffer?: boolean;
  isShowProductPrice?: boolean;
  isShowProductImage?: boolean;
  isShowProductVariant?: boolean;
}

export type CartProductsPropsCustom = {
  Products: CartProductsProps;
  Component?: {
    checkboxComponent: ICheckBoxComponent;
  }[];
  Border: {
    borderStyle: string;
    borderRadius: string;
    borderColor: string;
    borderThickness: string;
  };
  Background: {
    backgroundColor: string;
    hoverColor: string;
  };
};

export const CheckBox = ({
  useAppDispatch,
  useAppSelector,
  baseService,
}: ReduxDispatchProps &
  ReduxSelectorProps & {
    baseService: AxiosInstance;
  }): ComponentConfig<CartProductsPropsCustom> => {
  return {
    label: (
      <CustomTooltip title="TextField">
        <span>CheckBox</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      Products: {
        type: 'custom',
        render: ({ value, onChange }) => (
          <CustomCheckbox
            useAppSelector={useAppSelector}
            baseService={baseService}
            value={value}
            onChange={onChange}
          />
        ),
      },
      Component: {
        type: 'array',

        arrayFields: {
          checkboxComponent: {
            type: 'custom',
            render: ({ value, onChange }) => {
              const [isOpenProductName, setIsOpenProductName] = useState(false);
              const [isOpen, setIsOpen] = useState(false);
              const [isOpenProductVariant, setIsOpenProductVariant] =
                useState(false);
              const toggleAccordionProduct = () => {
                setIsOpenProductName(!isOpenProductName);
              };
              const toggleAccordionVariant = () => {
                setIsOpenProductVariant(!isOpenProductVariant);
              };

              const toggleAccordionOffer = () => {
                setIsOpen(!isOpen);
              };
              if (value.title === 'Checkbox')
                return (
                  <>
                    <div className="flex justify-between items-center toggleButton">
                      <p className="text-sm font-semibold">
                        Toggle or CheckBox
                      </p>
                      <div className="flex gap-0.5 items-center">
                        <Switch
                          className="switch-toggle"
                          checked={value?.isToggle}
                          onChange={event =>
                            onChange({
                              ...value,
                              isToggle: event?.currentTarget?.checked,
                            })
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Tooltip
                          title={'Toggle or Checkbox'}
                          placement="top-start">
                          <InfoIcon fontSize="small" className="h-1 w-1" />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex justify-between items-center toggleButton">
                      <p className="text-sm font-semibold">IsChecked</p>
                      <div className="flex gap-0.5 items-center">
                        <Switch
                          className="switch-toggle"
                          checked={value?.isChecked}
                          onChange={event =>
                            onChange({
                              ...value,
                              isChecked: event?.currentTarget?.checked,
                            })
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Tooltip
                          title={`Toggle or checkbox is to be checked`}
                          placement="top-start">
                          <InfoIcon fontSize="small" className="h-1 w-1" />
                        </Tooltip>
                      </div>
                    </div>
                  </>
                );
              else if (value?.title === 'Details') {
                return (
                  <div className="flex flex-col gap-3">
                    <div>
                      <div
                        className="flex justify-between font-extrabold cursor-pointer pb-5 bg-slate-100 rounded-md pagebuilder-theme"
                        onClick={toggleAccordionProduct}>
                        <h4>Product Name</h4>
                        {!isOpenProductName ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </div>
                      {isOpenProductName && (
                        <div className="flex gap-5 flex-col">
                          <div className="flex justify-between items-center toggleButton">
                            <p className="text-sm font-semibold">
                              Hide Product Name
                            </p>
                            <div className="flex gap-0.5 items-center">
                              <Switch
                                className="switch-toggle"
                                checked={value?.isShowProductName}
                                onChange={event =>
                                  onChange({
                                    ...value,
                                    isShowProductName:
                                      event.currentTarget.checked,
                                  })
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <Tooltip
                                title={`Hide the Product Name`}
                                placement="top-start">
                                <InfoIcon
                                  fontSize="small"
                                  className="h-1 w-1"
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <ColorSelector
                            value={value?.productTextColor}
                            onChange={val =>
                              onChange({ ...value, productTextColor: val })
                            }
                            label="Text Color"
                            tooltip="textColor"
                          />
                          <FieldLabel
                            label={
                              (
                                <CustomTooltip title={'Font Family'}>
                                  <span>Text Font Family</span>
                                </CustomTooltip>
                              ) as React.ReactNode as string
                            }
                            el="div">
                            <Select
                              value={value?.productTextFamily}
                              onChange={event =>
                                onChange({
                                  ...value,
                                  productTextFamily: event,
                                })
                              }
                              options={fontFamilies}
                            />
                          </FieldLabel>
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        className="flex justify-between font-extrabold cursor-pointer pb-5 bg-slate-100 rounded-md pagebuilder-theme"
                        onClick={toggleAccordionOffer}>
                        <h4>Product Offer</h4>
                        {!isOpen ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </div>
                      {isOpen && (
                        <div className="flex gap-5 flex-col">
                          <div className="flex justify-between items-center toggleButton">
                            <p className="text-sm font-semibold">
                              Hide Product Offer
                            </p>
                            <div className="flex gap-0.5 items-center">
                              <Switch
                                className="switch-toggle"
                                checked={value?.isShowProductOffer}
                                onChange={event =>
                                  onChange({
                                    ...value,
                                    isShowProductOffer:
                                      event.currentTarget.checked,
                                  })
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <Tooltip
                                title={`Hide the Product Offer`}
                                placement="top-start">
                                <InfoIcon
                                  fontSize="small"
                                  className="h-1 w-1"
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <FieldLabel
                            label={
                              (
                                <CustomTooltip title={'Font Family'}>
                                  <span>Text Font Family</span>
                                </CustomTooltip>
                              ) as React.ReactNode as string
                            }
                            el="div">
                            <Select
                              value={value?.offerTextFamily}
                              onChange={event =>
                                onChange({
                                  ...value,
                                  offerTextFamily: event,
                                })
                              }
                              options={fontFamilies}
                            />
                          </FieldLabel>
                          <ColorSelector
                            value={value?.offerTextColor}
                            onChange={val =>
                              onChange({ ...value, offerTextColor: val })
                            }
                            label="Text Color"
                            tooltip="textColor"
                          />
                          <ColorSelector
                            value={value?.offerBackgroundColor}
                            onChange={val =>
                              onChange({ ...value, offerBackgroundColor: val })
                            }
                            label="BackGround Color"
                            tooltip="backgroundColor"
                          />
                          <FieldLabel
                            label={
                              (
                                <CustomTooltip title={'Border Radius'}>
                                  <span>Border Radius</span>
                                </CustomTooltip>
                              ) as React.ReactNode as string
                            }
                            el="div"
                            className="w-full">
                            <Select
                              value={value?.offerRadius}
                              onChange={event =>
                                onChange({
                                  ...value,
                                  offerRadius: event,
                                })
                              }
                              options={borderRadiusOptions}
                            />
                          </FieldLabel>
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        className="flex justify-between font-extrabold cursor-pointer pb-5 bg-slate-100 rounded-md pagebuilder-theme"
                        onClick={toggleAccordionVariant}>
                        <h4>Product Variant</h4>
                        {!isOpenProductVariant ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </div>
                      {isOpenProductVariant && (
                        <div className="flex gap-5 flex-col">
                          <div className="flex justify-between items-center toggleButton">
                            <p className="text-sm font-semibold">
                              Hide Product Variant
                            </p>
                            <div className="flex gap-0.5 items-center">
                              <Switch
                                className="switch-toggle"
                                checked={value?.isShowProductVariant}
                                onChange={event =>
                                  onChange({
                                    ...value,
                                    isShowProductVariant:
                                      event.currentTarget.checked,
                                  })
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <Tooltip
                                title={`Hide the Product Variant`}
                                placement="top-start">
                                <InfoIcon
                                  fontSize="small"
                                  className="h-1 w-1"
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else if (value.title === 'Product Image') {
                return (
                  <div>
                    <h1 className="pb-5">Product Image</h1>
                    <div className="flex justify-between items-center toggleButton">
                      <p className="text-sm font-semibold">
                        Hide Product Image
                      </p>
                      <div className="flex gap-0.5 items-center">
                        <Switch
                          className="switch-toggle"
                          checked={value?.isShowProductImage}
                          onChange={event =>
                            onChange({
                              ...value,
                              isShowProductImage: event.currentTarget.checked,
                            })
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Tooltip
                          title={`Hide the Product Image`}
                          placement="top-start">
                          <InfoIcon fontSize="small" className="h-1 w-1" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div>
                  <div className="flex justify-between items-center toggleButton">
                    <p className="text-sm font-semibold">Hide Product Price</p>
                    <div className="flex gap-0.5 items-center">
                      <Switch
                        className="switch-toggle"
                        checked={value?.isShowProductPrice}
                        onChange={event =>
                          onChange({
                            ...value,
                            isShowProductPrice: event.currentTarget.checked,
                          })
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Tooltip
                        title={`Hide the Product Name`}
                        placement="top-start">
                        <InfoIcon fontSize="small" className="h-1 w-1" />
                      </Tooltip>
                    </div>
                  </div>
                  <ColorSelector
                    value={value?.amountTextColor}
                    onChange={val =>
                      onChange({ ...value, amountTextColor: val })
                    }
                    label="Text Color"
                    tooltip="textColor"
                  />
                </div>
              );
            },
          },
        },
        getItemSummary: ({ checkboxComponent }) => {
          return checkboxComponent?.title;
        },
        min: 2,
        max: 2,
      },
      Background: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => {
          const [isOpen, setIsOpen] = useState(false);
          const toggleAccordion = () => {
            setIsOpen(!isOpen);
          };
          return (
            <>
              <div
                className="flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
                onClick={toggleAccordion}>
                <h3>Customize Bg Color</h3>
                {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
              {isOpen && (
                <div className="flex flex-col gap-1  mt-3 items-center">
                  <div className="flex flex-col gap-4 w-full items-center border rounded p-2 pb-4">
                    <ColorSelector
                      value={value?.backgroundColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          backgroundColor: val,
                        });
                      }}
                      label="Background color"
                      tooltip="backgroundColor"
                    />
                    <ColorSelector
                      value={value?.hoverColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          hoverColor: val,
                        });
                      }}
                      label="Hover color"
                      tooltip="hoverColor"
                    />
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      Border: {
        type: 'custom',
        render: ({ value, onChange }) => {
          const [isOpen, setIsOpen] = useState(false);
          const toggleAccordion = () => {
            setIsOpen(!isOpen);
          };

          return (
            <>
              <div
                className="flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
                onClick={toggleAccordion}>
                <h3>Border</h3>
                {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
              {isOpen && (
                <div className="flex flex-col gap-1  mt-3 items-center">
                  <div className="flex flex-col gap-4 w-full items-center border rounded p-2 pb-4">
                    <FieldLabel
                      label={
                        (
                          <CustomTooltip title={'BorderStyle'}>
                            <span>Border Style</span>
                          </CustomTooltip>
                        ) as React.ReactNode as string
                      }
                      el="div"
                      className="w-full">
                      <Select
                        value={value?.borderStyle}
                        onChange={event => {
                          onChange({
                            ...value,
                            borderStyle: event,
                          });
                        }}
                        options={[
                          { label: 'None', value: 'none' },
                          { label: 'Solid', value: 'solid' },
                          { label: 'Dashed', value: 'dashed' },
                          { label: 'Dotted', value: 'dotted' },
                          { label: 'Double', value: 'double' },
                          { label: 'Groove', value: 'groove' },
                          { label: 'Ridge', value: 'ridge' },
                          { label: 'Inset', value: 'inset' },
                          { label: 'Outset', value: 'outset' },
                        ]}
                      />
                    </FieldLabel>
                    <FieldLabel
                      label={
                        (
                          <CustomTooltip title={'Border Radius'}>
                            <span>Border Thickness</span>
                          </CustomTooltip>
                        ) as React.ReactNode as string
                      }
                      el="div"
                      className="w-full">
                      <Select
                        value={value?.borderThickness}
                        onChange={event =>
                          onChange({
                            ...value,
                            borderThickness: event,
                          })
                        }
                        options={[
                          { label: 'None', value: '0px' },
                          { label: '1', value: '1px' },
                          { label: '2', value: '2px' },
                          { label: '3 ', value: '3px' },
                          { label: '4', value: '4px' },
                        ]}
                      />
                    </FieldLabel>
                    <ColorSelector
                      value={value?.borderColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          borderColor: val,
                        });
                      }}
                      label="Border color"
                      tooltip="BorderColor"
                    />
                    <FieldLabel
                      label={
                        (
                          <CustomTooltip title={'Border Radius'}>
                            <span>Border Radius</span>
                          </CustomTooltip>
                        ) as React.ReactNode as string
                      }
                      el="div"
                      className="w-full ">
                      <DiscreteSlider
                        value={
                          parseInt(value?.borderRadius.replace('px', '')) || 0
                        }
                        onChange={values => {
                          onChange({
                            ...value,
                            borderRadius: values.toString() + 'px',
                          });
                        }}
                      />
                    </FieldLabel>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
    },
    resolveFields: ({ props }, { fields }) => {
      if (props.Products.selectMethod !== 'product') {
        return {
          Products: fields.Products,
          Border: fields.Border,
          Component: [] as unknown as Field<
            { checkboxComponent: ICheckBoxComponent }[] | undefined
          >,
          Background: fields.Background,
        };
      } else {
        return {
          Products: fields.Products,
          Component: fields.Component,
          Border: fields.Border,
          Background: fields.Background,
        };
      }
    },
    defaultProps: {
      Background: {
        backgroundColor: '#FFFFFF',
        hoverColor: '#FFFFFF',
      },
      Border: {
        borderStyle: 'solid',
        borderRadius: 'rounded-none',
        borderColor: '#000000',
        borderThickness: '1px',
      },
      Component: [
        {
          checkboxComponent: {
            offerRadius: 'rounded-md',
            offerBackgroundColor: '#e24b4b',
            offerTextColor: '#000000',
            offerTextFamily: 'arial',
            title: 'Checkbox',
            productTextColor: '#000000',
            amountTextColor: '#000000',
            productTextFamily: 'arial',
            isShowProductName: false,
            isShowProductOffer: false,
            isShowProductPrice: false,
            isShowProductVariant: false,
          },
        },
        {
          checkboxComponent: {
            offerRadius: 'rounded-md',
            offerBackgroundColor: '#e24b4b',
            offerTextColor: '#000000',
            offerTextFamily: 'arial',
            title: 'Details',
            productTextColor: '#000000',
            amountTextColor: '#000000',
            productTextFamily: 'arial',
            isShowProductName: false,
            isShowProductOffer: false,
            isShowProductPrice: false,
            isShowProductVariant: false,
          },
        },
        {
          checkboxComponent: {
            offerRadius: 'rounded-md',
            offerBackgroundColor: '#e24b4b',
            offerTextColor: '#000000',
            offerTextFamily: 'arial',
            title: 'Product Image',
            productTextColor: '#000000',
            amountTextColor: '#000000',
            productTextFamily: 'arial',
            isShowProductName: false,
            isShowProductOffer: false,
            isShowProductPrice: false,
            isShowProductImage: false,
            isShowProductVariant: false,
          },
        },
        {
          checkboxComponent: {
            offerRadius: 'rounded-md',
            offerBackgroundColor: '#e24b4b',
            offerTextColor: '#000000',
            offerTextFamily: 'arial',
            title: 'Price',
            productTextColor: '#000000',
            amountTextColor: '#000000',
            productTextFamily: 'arial',
            isShowProductName: false,
            isShowProductOffer: false,
            isShowProductPrice: false,
            isShowProductVariant: false,
          },
        },
      ],
      Products: {
        varientOptions: [],
        variant: {
          ID: '',
          ImageURL: '',
          OriginalPrice: '',
          Price: '',
          Title: '',
        },
        product: {
          ID: '',
          ImageURL: 'https://via.placeholder.com/150',
          Title: 'Product Title',
          MinOriginalPrice: '',
          Description: 'Product Description',
          Handle: 'Product Handle',
          MaxDiscountedPrice: '50',
          MaxOriginalPrice: '',
          MinDiscountedPrice: '50',
          MembershipGroupsCount: 0,
        },
        selectMethod: '',
      },
    },
    render: ({ Products, Component, Border, Background }) => {
      const { pathname } = useLocation();
      const checkBoxComponent = Component?.find(
        value => value.checkboxComponent.title === 'Checkbox',
      );

      const [isChecked, setIsChecked] = useState(
        checkBoxComponent?.checkboxComponent.isChecked || false,
      );
      const dispatch = useAppDispatch();
      const cartData = useAppSelector(state => state.cart.cartData);
      const storeData = useAppSelector(state => state.store.storeData);
      const [selectedProduct, setSelectedProduct] =
        useState<IProductVariant | null>(null);
      const [variantSelected, setVariantSelected] = useState<IVariant | null>(
        null,
      );
      const updateCartItems = async (quantity: number) => {
        const lineItems = [
          ...(cartData?.LineItems ?? []).map((item: LineItem) => ({
            ID: item.ID,
            Quantity: item.Quantity,
            VariantID: item.VariantID,
          })),
        ];
        const payload = {
          ID: cartData?.ID,
          LineItems: lineItems.map(item => {
            if (item.VariantID === variantSelected?.ID) {
              return {
                ...item,
                Quantity: item.Quantity - quantity,
              };
            }
            return item;
          }, []),
          ShopID: storeData?.ShopID,
          StoreID: storeData?.StoreID,
        };
        try {
          dispatch(setIsCartLoading(true));
          const res = await checkoutService.checkoutUpdateItems(
            payload as CheckoutUpdateItemsPayload,
          );
          dispatch(setCartData(res?.data?.Cart));
          dispatch(
            setAvailableShippingMethods(
              res?.data?.Cart?.AvailableShippingMethods as V2ShippingMethod[],
            ),
          );
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(setIsCartLoading(false));
        }
      };

      const handleAdd = useCallback(async () => {
        dispatch(setIsCartLoading(true));
        if (!cartData?.ID || !storeData?.ShopID || !storeData?.StoreID) return;
        const lineItems = [
          {
            Quantity: 1,
            VariantID: variantSelected?.ID || '',
          },
        ];

        const payload: AddItemPayload = {
          ID: cartData?.ID,
          LineItems: lineItems,
          ShopID: storeData?.ShopID,
          StoreID: storeData?.StoreID,
        };

        try {
          const res = await checkoutService.checkoutAddItem(payload);
          dispatch(setCartData(res.data.Cart));
          dispatch(
            setAvailableShippingMethods(
              res.data.Cart.AvailableShippingMethods as ShippingMethod[],
            ),
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            dispatch(setCheckoutErrorMessage(error.message)); // TODO: error message to sentry
          }
        } finally {
          dispatch(setIsCartLoading(false));
        }
      }, [
        dispatch,
        cartData?.ID,
        storeData?.ShopID,
        storeData?.StoreID,
        variantSelected,
        selectedProduct,
        isChecked,
      ]);

      const getBatchProducts = async () => {
        if (!Products?.product?.ID || !storeData?.ShopID || !storeData?.StoreID)
          return;

        const response = await pageBuilderService.getProductsWithVariant(
          {
            ProductIDs: [Number(Products?.product?.ID?.split('/').pop())],
            ShopID: storeData?.ShopID,
            StoreID: storeData?.StoreID,
          },
          baseService,
        );

        setSelectedProduct(
          response?.data?.Result[0] || {
            ID: Products.product.Handle,
            ImageURL: Products.product.ImageURL,
            Title: Products.product.Title,
            Variants: [],
          },
        );
        setVariantSelected(
          (response?.data?.Result[0]?.Variants as IVariant[])?.find(
            value => value?.ID === Products?.variant?.ID,
          ) ||
            Products.variant ||
            null,
        );
      };

      const getCheckBoxDatas = (component: ICheckBoxComponent) => {
        return (
          <div className="flex flex-col items-center justify-center">
            {!component?.isToggle ? (
              <input
                type="checkbox"
                onClick={() => {
                  setIsChecked(prev => {
                    if (!prev) handleAdd();
                    else updateCartItems(1);
                    return !prev;
                  });
                }}
                checked={isChecked}
              />
            ) : (
              <ToggleButton
                checked={isChecked}
                handleToggle={() => {
                  setIsChecked(prev => {
                    if (!prev) handleAdd();
                    else updateCartItems(1);
                    return !prev;
                  });
                }}
              />
            )}
          </div>
        );
      };

      const getProductDetails = (component: ICheckBoxComponent) => {
        return (
          <div className="flex flex-row items-center justify-between gap-3 w-full">
            <div className="flex items-center ml-2.5 gap-2">
              <div className="flex flex-col gap-2">
                {!component?.isShowProductOffer && (
                  <div
                    className={`${component.offerRadius} text-xs font-bold px-3 py-1.5 w-fit `}
                    style={{
                      background: component?.offerBackgroundColor,
                      color: component?.offerTextColor,
                      fontFamily: component?.offerTextFamily,
                    }}>
                    Offers End Today
                  </div>
                )}
                {!component?.isShowProductName && (
                  <div
                    className="text-base font-medium text-gray-800"
                    style={{
                      color: component?.productTextColor,
                      fontFamily: component?.productTextFamily,
                    }}>
                    {selectedProduct?.Title}
                  </div>
                )}
                {!component?.isShowProductVariant &&
                  variantSelected?.ID &&
                  selectedProduct?.Variants &&
                  selectedProduct?.Variants.length > 0 && (
                    <SelectForm
                      options={
                        selectedProduct?.Variants.map(variant => ({
                          label: variant?.Title
                            ? variant?.Title
                            : selectedProduct?.Title,
                          value: variant?.ID,
                        })) ?? []
                      }
                      onChange={event => {
                        const selectedVariant = selectedProduct?.Variants?.find(
                          variant => variant.ID === event.target.value,
                        );
                        setVariantSelected(selectedVariant ?? null);
                      }}
                      additionalStyles={'!text-gray-700'}
                      valueKey="value"
                      labelKey="label"
                      value={variantSelected?.ID || Products?.variant?.ID}
                    />
                  )}
              </div>
            </div>
          </div>
        );
      };

      const getPrice = (component: ICheckBoxComponent) => {
        return (
          <div className="flex flex-col items-center">
            {!component?.isShowProductPrice && (
              <span
                className="text-xl font-bold text-gray-900"
                style={{
                  color: component?.amountTextColor,
                }}>
                ${variantSelected?.Price || Products?.product?.MaxOriginalPrice}
              </span>
            )}
          </div>
        );
      };

      const getImage = (component: ICheckBoxComponent) => {
        return (
          <div className="flex flex-col items-center">
            {!component?.isShowProductImage && (
              <img
                src={Products?.product?.ImageURL}
                alt="Product"
                className="w-16 h-16 mr-4 rounded-lg"
              />
            )}
          </div>
        );
      };

      const getComponentOfCart = (
        title: string,
        component: ICheckBoxComponent,
      ) => {
        if (title === 'Checkbox') return getCheckBoxDatas(component);
        if (title === 'Details' && Products?.selectMethod === 'product')
          return getProductDetails(component);
        if (title === 'Price' && Products?.selectMethod === 'product')
          return getPrice(component);
        if (title === 'Product Image' && Products?.selectMethod === 'product')
          return getImage(component);
      };

      useEffect(() => {
        setIsChecked(checkBoxComponent?.checkboxComponent.isChecked || false);
      }, [checkBoxComponent?.checkboxComponent.isChecked]);

      useEffect(() => {
        setVariantSelected(Products?.variant || null);
        if (pathname.includes(Path.PAGE_BUILDER_EDIT))
          setSelectedProduct({
            ID: Products.product.ID,
            ImageURL: Products.product.ImageURL,
            Title: Products.product.Title,
            Variants: Products.varientOptions,
          });
      }, [Products?.variant]);

      useEffect(() => {
        if (pathname.includes(Path.PAGE_BUILDER_VIEW) && !selectedProduct)
          getBatchProducts();
      }, []);

      useEffect(() => {
        if (pathname.includes(Path.PAGE_BUILDER_EDIT)) {
          setSelectedProduct({
            ID: Products.product.ID,
            ImageURL: Products.product.ImageURL,
            Title: Products.product.Title,
            Variants: [Products.variant],
          });
        }
      }, [Products.product]);

      useEffect(() => {
        if (
          selectedProduct &&
          isChecked &&
          pathname.includes(Path.PAGE_BUILDER_VIEW)
        ) {
          const isProductInCart = cartData?.LineItems?.some(item => {
            return item.ID === selectedProduct?.ID;
          });

          if (isProductInCart) {
            updateCartItems(1);
          } else {
            handleAdd();
          }
        }
      }, [selectedProduct]);

      return (
        <div
          onMouseOver={e => {
            e.currentTarget.style.backgroundColor = Background?.hoverColor;
          }}
          onMouseOut={e => {
            e.currentTarget.style.backgroundColor = Background?.backgroundColor;
          }}
          style={{
            border: `${Border?.borderThickness} ${Border?.borderStyle} ${Border?.borderColor}`,
            borderRadius: Border?.borderRadius,
            backgroundColor: Background?.backgroundColor,
          }}
          className="flex justify-between p-4 items-center">
          {Component?.map(item => (
            <div key={item?.checkboxComponent?.title} className="pl-4">
              {getComponentOfCart(
                item.checkboxComponent.title,
                item.checkboxComponent,
              )}
            </div>
          ))}
        </div>
      );
    },
  };
};
