import { baseService } from 'services';
import {
  commonError,
  deleteTaskEP,
  getTaskDetailEP,
  getTaskListEP,
  reattemptTaskEP,
} from './constants';
import { ITaskBody, ITaskResponse } from 'interface/tasks';
import { AxiosError } from 'axios';
import { ITaskID } from 'components/tasks';

class TaskService {
  getTaskList = async (payload: ITaskBody) => {
    try {
      const res = await baseService.post<ITaskResponse>(getTaskListEP, payload);
      return res;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getTaskListByID = async (id: string) => {
    try {
      const res = await baseService.get<ITaskResponse>(
        `${getTaskDetailEP(id)}`,
      );
      return res;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteTask = async (data: ITaskID) => {
    try {
      const res = await baseService.post(deleteTaskEP, data);
      return res;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  reattemptTask = async (data: ITaskID) => {
    try {
      const res = await baseService.post(reattemptTaskEP, data);
      return res;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

export default new TaskService();
