import { AxiosError } from 'axios';
import {
  ICreateMemberShipOffer,
  IFormOffers,
  IMemberShipProducts,
  IMembershipOffers,
  IOffers,
  IProductBatch,
  IProductBody,
  IProductWithVarientResponse,
  IProductsOfStore,
  IUpdateMembershipOffer,
} from 'interface/membershipOffersInterface';
import { baseService } from 'services';
import {
  batchProducts,
  commonError,
  createMembershipOffersEP,
  getDeleteMembershipOffersEP,
  getMembershipOfferByIDEP,
  getMembershipOffersEP,
  getMembershipProducts,
  getProducts,
  getProductVarients,
  updateMembershipOffersEP,
} from 'services/constants';

class MembershipOfferService {
  getMembershipOfferProducts = async (body: IProductBody) => {
    try {
      const response = await baseService.post<IMemberShipProducts>(
        getMembershipProducts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createMembershipOffer = async (body: ICreateMemberShipOffer) => {
    try {
      const response = await baseService.post<{ ID: string }>(
        createMembershipOffersEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getMembershipOffers = async (body: IOffers) => {
    try {
      const response = await baseService.post<IMembershipOffers>(
        getMembershipOffersEP,
        body,
      );
      return response;
    } catch (err) {
      return commonError(err as AxiosError, true);
    }
  };

  getMembershipOfferByID = async (channel_id: string, id: string) => {
    try {
      const response = await baseService.get<IFormOffers>(
        getMembershipOfferByIDEP(channel_id, id),
      );
      return response;
    } catch (err) {
      return commonError(err as AxiosError, true);
    }
  };

  updateMembershipOffer = async (body: IUpdateMembershipOffer) => {
    try {
      const response = await baseService.post<{ ID: string }>(
        updateMembershipOffersEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getAllProducts = async (body: IProductsOfStore) => {
    try {
      const response = await baseService.post<IProductsOfStore>(
        getProducts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getProductWithVariants = async (body: IProductBatch) => {
    try {
      const response = await baseService.post<IProductWithVarientResponse>(
        getProductVarients,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  batchProducts = async (body: IProductBatch) => {
    try {
      const response = await baseService.post<IProductsOfStore>(
        batchProducts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteMembershipOffers = async (channel_id: string, id: string) => {
    try {
      return await baseService.delete<{ ID: string }>(
        getDeleteMembershipOffersEP(channel_id, id),
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

export default new MembershipOfferService();
