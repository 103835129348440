import React from 'react';

import { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { ReactComponent as ResendIcon } from 'assets/icons/resend-icon.svg';
import { Loader } from 'components/common/loader';
import CreateIcon from '@mui/icons-material/Create';
import {
  ICreateUserPermission,
  IRemoveUserPermission,
  IUserInvitation,
} from 'interface/settingsInterface';
import userService from 'services/userService';
import { IDeleteInvites, IInvitation } from 'interface/userInterface';
import StatusBadge from 'components/common/statusBadge';
import { showNotification } from 'helper/common/commonFunctions';
import AddUserPermissionsForm from 'components/Actions/addUserPermissions';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import CommonTable from 'components/common/TableCommon';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IInvitation) => ReactNode;
  hide?: boolean;
}

export interface IUserPermissions {
  Email: string;
  Permissions?: string[];
  CreatedAt?: string;
  ID?: string;
  Status?: string;
  Store?: string;
  SuperAdmin?: boolean;
  Version?: number;
}

interface IUserPermissionProps {
  storeID?: string;
}

export default function UserPermission({ storeID }: IUserPermissionProps) {
  const [isNextAPIDisabledForInvites, setIsNextAPIDisabledForInvites] =
    useState<boolean>(false);
  const [isNextAPIDisabledForUsers, setIsNextAPIDisabledForUsers] =
    useState<boolean>(false);
  const [deletedRow, setDeletedRow] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const limit = 25;
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IInvitation[]>([]);
  const [showAddEditMode, setShowAddEditMode] = useState<boolean>(false);
  const defaultPermissionsValues = {
    Email: '',
    Permissions: [],
    StoreID: storeID ? storeID : '',
    SuperAdmin: false,
  };
  const [selected, setSelected] = useState<ICreateUserPermission>(
    defaultPermissionsValues,
  );
  const headCellList: HeadCell[] = [
    {
      id: 'Email',
      label: 'User Email',
      hide: false,
      cellRender: row => {
        return <>{row.Email}</>;
      },
    },
    {
      id: 'Permissions',
      label: 'Permissions',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="contant">
              {row?.Permissions?.map((permission, index) => {
                return (
                  <div className="value permission" key={index}>
                    <p>{permission}</p>
                  </div>
                );
              })}
            </div>
          </>
        );
      },
    },
    {
      id: 'Status',
      label: 'Status',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="contant">
              <div className="value">
                <StatusBadge status={row?.Status || 'accepted'} />
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="flex items-center justify-start gap-3">
              {row.Status === 'declined' && (
                <ResendIcon
                  onClick={() => resendInvite(row.Store.ID, row.ID)}
                />
              )}
              <DeleteIcon
                className="h-15"
                onClick={() => {
                  const data = {
                    Email: row?.Email,
                    Permissions: row?.Permissions,
                    StoreID: row?.Store?.ID,
                    ID: row?.ID,
                    SuperAdmin: row?.SuperAdmin,
                    Version: row?.Version,
                  };
                  setDeletedRow(row?.Store ? 'invites' : 'users');
                  setSelected(data);
                }}
              />
              <CreateIcon
                onClick={() => {
                  setShowAddEditMode(true);
                  const data = {
                    Email: row?.Email,
                    Permissions: row?.Permissions,
                    StoreID: row?.Store?.ID || storeID || '',
                    currentRow: row?.Store ? 'invites' : 'users',
                    ID: row?.ID,
                    SuperAdmin: row?.SuperAdmin,
                    Version: row?.Version,
                  };
                  setSelected(data);
                }}
              />
            </div>{' '}
          </>
        );
      },
    },
  ];

  const resendInvite = async (storeId: string, inviteId: string) => {
    setLoading(true);
    const res = await userService.reSendInvite({
      InviteID: inviteId,
      StoreID: storeId,
    });
    setLoading(false);
    if (res?.status === 200) {
      showNotification('success', 'Invitation Sended SuccessFully');
    }
  };

  const getUsers = async () => {
    setLoading(true);
    const payload: IUserInvitation = {
      Descending: false,
      Limit: limit,
      OrderBy: 'CreatedAt',
      Page: page - 1,
      StoreID: storeID ? storeID : '',
    };
    const promises = [
      !isNextAPIDisabledForInvites
        ? userService.getStoreInvites(payload)
        : undefined,
      !isNextAPIDisabledForUsers
        ? userService.getUsersPermission(payload)
        : undefined,
    ];
    const [inviteResponse, usersResponse] = await Promise.all(promises);

    setLoading(false);
    const inviteRes = inviteResponse?.data?.Result || [];
    const usersRes = usersResponse?.data?.Result || [];
    if (inviteRes || usersRes) {
      const sortedData = [...inviteRes, ...usersRes].sort(
        (a, b) =>
          new Date(b?.CreatedAt).getTime() - new Date(a?.CreatedAt)?.getTime(),
      );
      const filteredData = sortedData?.filter(
        item => item?.Status === 'pending' || !item?.Status,
      );
      if (!isNextAPIDisabledForInvites) {
        setIsNextAPIDisabledForInvites(limit > inviteRes?.length);
      }
      if (!isNextAPIDisabledForUsers) {
        setIsNextAPIDisabledForUsers(limit > usersRes?.length);
      }
      setUsers(prev => [...prev, ...filteredData]);
    } else if (!promises) {
      setUsers([]);
    }
  };

  const deleteInvite = async (ID: string) => {
    let res;
    setLoading(true);
    if (deletedRow === 'invites') {
      const invitepayload: IDeleteInvites = {
        InviteIDs: [ID],
        StoreID: storeID ?? '',
      };
      res = await userService.deleteInvite(invitepayload);
    } else if (deletedRow === 'users') {
      const permissionPayload: IRemoveUserPermission = {
        StoreID: storeID ?? '',
        UserID: ID,
      };
      res = await userService.removeUserPermission(permissionPayload);
    }
    setLoading(false);
    if (res?.status === 200 || res?.status === 204) {
      showNotification(
        'success',
        `${deletedRow === 'invites' ? 'Invite' : 'user permission'} deleted successfully`,
      );
      setUsers([]);
      setPage(1);
      setIsNextAPIDisabledForInvites(false);
      setIsNextAPIDisabledForUsers(false);
    }
    setDeletedRow('');
  };

  useEffect(() => {
    if (
      (!isNextAPIDisabledForInvites || !isNextAPIDisabledForUsers) &&
      (users.length === 0 || page > 1)
    ) {
      getUsers();
    }
  }, [storeID, page, isNextAPIDisabledForInvites, isNextAPIDisabledForUsers]);

  return (
    <>
      <CommonTable<IInvitation>
        addLabelName="Add User Permissions"
        isNextAPIDisabled={
          isNextAPIDisabledForInvites && isNextAPIDisabledForUsers
        }
        headCellList={headCellList}
        data={users}
        onEndReached={() => {
          setPage((prevPage: number) => prevPage + 1);
        }}
        onAdd={() => {
          setShowAddEditMode(true);
          setSelected(defaultPermissionsValues);
        }}
      />
      <Loader loading={loading} />
      {showAddEditMode && (
        <AddUserPermissionsForm
          defaultUserPermissionValues={selected}
          handleClose={() => setShowAddEditMode(false)}
          onSuccess={() => {
            setUsers([]);
            setPage(1);
            setIsNextAPIDisabledForInvites(false);
            setIsNextAPIDisabledForUsers(false);
            setShowAddEditMode(false);
          }}
        />
      )}
      <ConfirmationDialog
        open={Boolean(deletedRow)}
        handleClose={() => setDeletedRow('')}
        message="delete user permission"
        onSubmit={() => {
          deleteInvite(selected.ID ?? '');
        }}
      />
    </>
  );
}
