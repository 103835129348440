// import { Button } from 'shared-components';
import { Select } from 'shared-components';
import React, { ReactNode, useState } from 'react';
import 'components/cascade/style.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import MerchantTable from 'components/cascade/MerchantTable';
import { get } from 'lodash';
import { PopUpModal } from 'shared-components';
import ActionLogsTable from 'components/cascade/ActionLogsTable';
export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: MerchantData) => ReactNode;
  hide?: boolean;
}
interface UserLogs {
  name: string;
  traffic: number;
  revenueLimit: number;
  transactionLimit: number;
}

// import SideFilter from 'components/SubscriptionManagement/SideFilter';
interface MerchantData {
  merchantAccount: string;
  currentMonthRevenue: number;
  currentMonthTransactions: number;
  trafficPercent: number;
  revenueLimit: number;
  transactionLimit: number;
}

const mockData: MerchantData[] = [
  {
    merchantAccount: 'Benjamin Thompson',
    currentMonthRevenue: 206.9,
    currentMonthTransactions: 73,
    trafficPercent: 5,
    revenueLimit: 2200.0,
    transactionLimit: 100,
  },
  {
    merchantAccount: 'Sophia Rodriguez',
    currentMonthRevenue: 1459.89,
    currentMonthTransactions: 67,
    trafficPercent: 30,
    revenueLimit: 1200.0,
    transactionLimit: 80,
  },
  {
    merchantAccount: 'Elijah Chen',
    currentMonthRevenue: 206450.8,
    currentMonthTransactions: 48,
    trafficPercent: 5,
    revenueLimit: 500.0,
    transactionLimit: 50,
  },
  {
    merchantAccount: 'Isabella Martinez',
    currentMonthRevenue: 250.0,
    currentMonthTransactions: 34,
    trafficPercent: 10,
    revenueLimit: 1000.0,
    transactionLimit: 80,
  },
  {
    merchantAccount: 'Ava Davis',
    currentMonthRevenue: 170.56,
    currentMonthTransactions: 76,
    trafficPercent: 20,
    revenueLimit: 2500.0,
    transactionLimit: 120,
  },
  {
    merchantAccount: 'Jackson Williams',
    currentMonthRevenue: 269.8,
    currentMonthTransactions: 88,
    trafficPercent: 2,
    revenueLimit: 500.0,
    transactionLimit: 50,
  },
];

const merchantsData: UserLogs[] = [
  {
    name: 'Benjamin Thompson',
    traffic: 5,
    revenueLimit: 2200.0,
    transactionLimit: 100,
  },
  {
    name: 'Sophia Rodriguez',
    traffic: 30,
    revenueLimit: 1200.0,
    transactionLimit: 80,
  },
  {
    name: 'Isabella Martinez',
    traffic: 10,
    revenueLimit: 1000.0,
    transactionLimit: 80,
  },
  {
    name: 'Jackson Williams',
    traffic: 2,
    revenueLimit: 500.0,
    transactionLimit: 50,
  },
  {
    name: 'Liam Smith',
    traffic: 8,
    revenueLimit: 850.0,
    transactionLimit: 60,
  },
];

const headCellList: HeadCell[] = [
  {
    id: 'merchantAccount',
    label: 'Merchant Account',
    hide: false,
  },
  {
    id: 'currentMonthRevenue',
    label: 'Current Month Revenue',
    hide: false,
  },
  {
    id: 'currentMonthTransactions',
    label: 'Current Month Revenue',
    hide: false,
  },
  {
    id: 'trafficPercent',
    label: 'Traffic %',
    hide: false,
    cellRender: row => {
      return <input className="input_edit" value={row.trafficPercent} />;
    },
  },
  {
    id: 'revenueLimit',
    label: 'Revenue Limit',
    hide: false,
    cellRender: row => {
      return <input className="input_edit" value={row.revenueLimit} />;
    },
  },
  {
    id: 'transactionLimit',
    label: 'Transaction Limit',
    hide: false,
    cellRender: row => {
      return <input className="input_edit" value={row.transactionLimit} />;
    },
  },
];
export default function Cascade() {
  const [websiteName, setwebsiteName] = useState<string>('');
  const [isPopupOpen, setPopupOpen] = useState<{
    error: boolean;
    preview: boolean;
  }>({
    error: false,
    preview: false,
  });

  return (
    <>
      <div className="cascade_container">
        <div className="top_bar">
          <Select
            label="Select Website"
            options={[{ label: 'www.store.com', value: 'store.com' }]}
            value={websiteName}
            onChange={value => setwebsiteName(value)}
            className="select_website"
          />
          <button
            className="update_button"
            onClick={() => {
              setPopupOpen(val => ({ ...val, preview: true }));
            }}>
            Update
          </button>
        </div>
        <p className="last_update">last updated on 12/04/2024</p>
        <div className="cascade">
          <div className="risk_wrapper_container">
            <div className="risk-container">
              <div className="risk_management">
                <TableContainer className="Common_Table">
                  <Table
                    className="risk_management_table"
                    aria-labelledby="tableTitle"
                    stickyHeader>
                    <TableHead className="table_header">
                      <TableRow>
                        {headCellList?.map(headCell => {
                          if (headCell.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_header_cell header_text"
                              key={headCell.label}>
                              {headCell.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {mockData.length > 0 ? (
                        mockData.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              className="table_row"
                              role="checkbox"
                              tabIndex={-1}
                              key={index}>
                              {headCellList.map(headCell => {
                                if (headCell.hide) {
                                  return null;
                                }
                                return (
                                  <TableCell
                                    className="table_cell"
                                    key={headCell.label}
                                    component="th"
                                    id={headCell.id}
                                    scope="row">
                                    <>
                                      {headCell?.cellRender
                                        ? headCell.cellRender(row)
                                        : get(row, headCell.id)}
                                    </>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="!py-20 !left-[50%] pointer-events-none">
                            <div className="no-data-row">No data found</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="actions">
          <p className="action_logs">Action Logs</p>
          <MerchantTable />
        </div>
      </div>
      {isPopupOpen.error || isPopupOpen.preview ? (
        <PopUpModal
          heading="Error - Could Not Update"
          open={isPopupOpen.error}
          handleClose={() => {
            setPopupOpen(val => ({ ...val, error: false }));
          }}
          buttons={[
            {
              buttonType: 'negative',
              buttonLabel: 'Close',
              buttonFunction: () => {
                setPopupOpen(val => ({ ...val, error: false }));
              },
            },
          ]}>
          <div className="popup_section_content_error">
            <div className="content-error">
              <p className="error">
                {' '}
                Please correct the errors below to process update.
              </p>
              <div className="content">
                <span>
                  . Percentage(%) doesn’t add up Traffic required to fill
                </span>
              </div>
            </div>
          </div>
        </PopUpModal>
      ) : null}
      {isPopupOpen.preview ? (
        <PopUpModal
          heading="Confirm"
          open={isPopupOpen.preview}
          handleClose={() => {
            setPopupOpen(val => ({ ...val, preview: false }));
          }}
          buttons={[
            {
              buttonType: 'negative',
              buttonLabel: 'Close',
              buttonFunction: () => {
                setPopupOpen(val => ({ ...val, preview: false }));
              },
            },
            {
              buttonType: 'positive',
              buttonLabel: 'Confirm',
              buttonFunction: () => {
                setPopupOpen(val => ({ ...val, preview: false }));
              },
            },
          ]}>
          <div className="popup-section-preview-table">
            <div className="popup-table">
              <ActionLogsTable
                data={merchantsData}
                paddingClassName="table-cell-padding-1"
              />
            </div>
          </div>
        </PopUpModal>
      ) : null}
    </>
  );
}
