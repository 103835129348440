import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Dayjs } from 'dayjs';
import { showNotification } from 'helper/common/commonFunctions';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import TextField from 'components/common/textField';
import { subscription_frequency } from 'utils/helper';
import DatePicker from 'components/common/datePicker';
import { Select } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';

interface IProps {
  payload: ISubscriptionManagementUpdateBody | null;
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
}

interface IMoveSubscription {
  Merchant: string;
  NextBillingDate: Dayjs | null;
  Amount: string;
  Frequency: string;
}

const dropdownValues = [
  { label: '350', value: '350' },
  { label: '450', value: '450' },
  { label: '800', value: '800' },
];

const MoveSubscription: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IMoveSubscription>({
    defaultValues: {
      Merchant: '',
      NextBillingDate: '' || null,
      Amount: '',
      Frequency: '',
    },
  });

  const moveSubscription = async (data: IMoveSubscription) => {
    if (!payload) return;
    setLoading(true);
    payload.NextBillingDate = data?.NextBillingDate?.format();
    payload.Amount = data.Amount;
    payload.Frequency = data.Frequency;
    const res =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (res.status === 200) {
      showNotification('success', 'Move Subscription updated successfully');
      handleClose();
      onApiResponse(true);
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'MoveSubscription',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      className="flex-container"
      heading={'Move Subscription'}>
      <div className="popup-section">
        <form
          id="MoveSubscription"
          onSubmit={handleSubmit(moveSubscription)}
          className="order-detail_refund">
          <div className="fields">
            <div className="popup-section">
              <Controller
                name="Merchant"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      label="Merchant"
                      value={value}
                      onChange={onChange}
                      className="filter_form_input"
                      placeholder="Enter Merchant"
                    />
                  );
                }}
              />
            </div>
            <div className="popup-section">
              <Controller
                name="NextBillingDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      label="Date"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                rules={{
                  required: 'Date is required',
                  validate: value => {
                    if (value?.date()) {
                      return true;
                    }
                    return 'Invalid date';
                  },
                }}
              />
            </div>
            <div className="popup-section">
              <Controller
                name="Amount"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      label="Amount"
                      options={dropdownValues}
                      placeholder="other"
                      error={error?.message}
                    />
                  );
                }}
                rules={{
                  required: 'Amount is required',
                }}
              />
            </div>
            <div className="popup-section">
              <Controller
                name="Frequency"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      label="Frequency"
                      options={subscription_frequency}
                      placeholder="Days"
                      error={error?.message}
                    />
                  );
                }}
                rules={{
                  required: 'Frequency is required',
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </PopUpModal>
  );
};

export default MoveSubscription;
