import { useFormContext } from 'react-hook-form';
import {
  RadioControl,
  RadioOptions,
} from '../../../../page-builder/components/form-fields/Radio';
import { AddressForm } from '.././AddressForm';
import { useEffect } from 'react';

const BillingForm = () => {
  const addressOptions: RadioOptions[] = [
    { id: '1', name: 'Same as shipping address', value: true },
    { id: '2', name: 'Use a different billing address', value: false },
  ];

  const { watch, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (!getValues('billingFormControl.isComponentMounted'))
      setValue('billingFormControl.isComponentMounted', true);
    return () => {
      setValue('billingFormControl.isComponentMounted', false);
    };
  }, []);

  return (
    <>
      <RadioControl
        name="billingFormControl.billing_same_as_shipping"
        options={addressOptions}
      />

      {!watch('billingFormControl.billing_same_as_shipping') && (
        <div className="mt-10">
          <AddressForm fieldPrefix="BillingAddress" />
        </div>
      )}
    </>
  );
};

export default BillingForm;
