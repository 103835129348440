import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Loader } from 'components/common/loader';
import SearchFilter from 'components/common/searchFilter';
import { get } from 'lodash';
import React, { ReactNode, useRef, useState, useEffect } from 'react';
import {
  formattedDate,
  getTotalPages,
  iterateHeadCellKeys,
  setPaginationCommon,
} from 'utils/helper';
import './style.scss';

export interface SearchProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

import {
  ITaskBody,
  ITaskFilter,
  ITaskFormFilter,
  ITasksData,
} from 'interface/tasks';
import TaskService from 'services/taskService';
import { useSearchParams } from 'react-router-dom';
import StatusBadge from 'components/common/statusBadge';
import PaginationCommon from 'components/common/PaginationCommon';
import SideFilter from 'components/tasks/Filter/sideFilter';
import { useAppSelector } from 'hooks/reduxHooks';
import dayjs from 'dayjs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NestedTableComponent from './nestedTableComponent';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/resend-icon.svg';
import { showNotification } from 'helper/common/commonFunctions';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import { queueData, statusOption } from 'utils/constants';
export const defaultTaskValues: ITaskFormFilter = {
  DateRange: '',
  StartTime: null,
  EndTime: null,
};

export interface ITaskID {
  TaskID: string;
}

const defaultValue = {
  isDelete: false,
  selected: null,
  isReattempt: false,
};
export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ITasksData) => ReactNode;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  inputType?: 'text' | 'number' | 'date';
  searchFieldOptions?: { label: string; value: string }[];
}
const Tasks: React.FC = () => {
  const headCellList: HeadCell[] = [
    {
      id: 'CreatedAt',
      label: 'Date',
      key: 'created_at',
      showSortIcon: true,
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'Queue',
      label: 'Queue',
      key: 'queue',
      showSortIcon: true,
      showInSearch: true,
      cellRender: row => {
        return row?.Queue ? row.Queue : '-';
      },
      searchFiedtType: 'select',
      searchFieldOptions: queueData,
    },
    {
      id: 'RetryCount',
      label: 'Retry Count',
      key: 'retry_count',
      showSortIcon: true,
      cellRender: row => {
        return row?.RetryCount !== null ? row.RetryCount : '-';
      },
    },
    {
      id: 'DelayUntil',
      label: 'Delay Until',
      key: 'DelayUntil',
      showSortIcon: true,
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.DelayUntil)} arrow>
            <span>
              {row.DelayUntil ? formattedDate(row?.DelayUntil, true) : '-'}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: 'Status',
      label: 'Task Status',
      key: 'status',
      showSortIcon: true,
      cellRender: row => {
        return <StatusBadge status={row?.Status} />;
      },
      showInSearch: true,
      searchFiedtType: 'select',
      searchFieldOptions: statusOption,
    },
    {
      id: 'delete',
      label: 'Delete Task',
      key: 'delete',
      showSortIcon: false,
      cellRender: row => {
        return (
          <>
            <div className="flex items-center justify-start gap-3">
              <DeleteIcon
                className="h-15"
                onClick={() => {
                  setSelectTaskDetails({
                    isDelete: true,
                    selected: row,
                    isReattempt: false,
                  });
                }}
              />
            </div>{' '}
          </>
        );
      },
    },
    {
      id: 'reattempt',
      label: 'Reattempt Task',
      key: 'reattempt',
      showSortIcon: false,
      cellRender: row => {
        if (row.Status === 'deadlettered') {
          return (
            <>
              <div className="flex items-center justify-start gap-3">
                <ResendIcon
                  className="h-15"
                  onClick={() => {
                    setSelectTaskDetails({
                      isReattempt: true,
                      selected: row,
                      isDelete: false,
                    });
                  }}
                />
              </div>{' '}
            </>
          );
        } else {
          return <div className="flex items-center justify-start gap-3">-</div>;
        }
      },
    },
  ];
  const [selectTaskDetails, setSelectTaskDetails] = useState<{
    isDelete: boolean;
    isReattempt: boolean;
    selected: ITasksData | null;
  }>(defaultValue);
  const [loading, setLoading] = useState<number>(0);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);

  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<
    Map<number, ITasksData[]>
  >(new Map());
  const [limit, setLimit] = useState<number>(25);
  const [page, setPage] = useState<number>(1);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [openRows, setOpenRows] = useState<boolean[]>();
  const [width, setWidth] = useState<number | undefined>(undefined);
  const filteredField = iterateHeadCellKeys([...headCellList]);
  const [search, setSearchValue] = useState<SearchProps>({});
  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const reset = () => {
    setSelectTaskDetails(defaultValue);
  };
  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };
  const [sideFormFilter, setSideFormFilter] = useState<ITaskFormFilter>({
    DateRange: searchParams.get('DateRange')
      ? searchParams.get('DateRange')
      : '',
    StartTime: searchParams.get('StartTime')
      ? dayjs(searchParams.get('StartTime')).tz()
      : null,
    EndTime: searchParams.get('EndTime')
      ? dayjs(searchParams.get('EndTime')).tz()
      : null,
    Queue: searchParams.get('Queue')
      ? JSON.parse(searchParams.get('Queue') || '')
      : [],
    Status: searchParams.get('Status')
      ? JSON.parse(searchParams.get('Status') || '')
      : [],
  });
  const ref = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<ITaskFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: 25,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
    Page: 0,
  });
  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };
  const filterSubmission = (formData: ITaskFormFilter) => {
    if (!loading) {
      setSideFormFilter(formData);
      setPage(1);
    }
  };
  const deleteTask = async (id: string) => {
    setLoading(prev => prev + 1);
    try {
      const data: ITaskID = {
        TaskID: id,
      };
      const res = await TaskService.deleteTask(data);
      if (res?.status === 200 || res?.status === 204) {
        showNotification('success', ` deleted successfully`);
        searchTaskList({ reset: true });
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    } finally {
      setLoading(prev => prev - 1);
    }
  };
  const reattemptTask = async (id: string) => {
    setLoading(prev => prev + 1);
    try {
      const data: ITaskID = {
        TaskID: id,
      };
      const res = await TaskService.reattemptTask(data);
      if (res?.status === 200 || res?.status === 204) {
        showNotification('success', ` Reattempted  successfully`);
        searchTaskList({ reset: true });
      }
    } catch (error) {
      console.error('Error reattempting task:', error);
    } finally {
      setLoading(prev => prev - 1);
    }
  };
  const generatePayload = (isSetSearchParams = true) => {
    const { Status, EndTime, StartTime, DateRange, Queue } = sideFormFilter;
    const { OrderBy, Descending } = filter;
    const payload: ITaskBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (StartTime) {
      payload.StartTime = StartTime.tz().startOf('day').format();
    }
    if (EndTime) {
      payload.EndTime = EndTime.tz().add(1, 'day').startOf('day').format();
    }
    if (Queue?.length) {
      payload.Queue = Queue?.map(item => item.value);
    }

    if (Status?.length) {
      payload.Status = Status.map(item => item.value);
    }

    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    if (isSetSearchParams) {
      setSearchParams(
        {
          DateRange: DateRange || '',
          StartTime: StartTime ? StartTime.tz().format() : '',
          EndTime: EndTime ? EndTime.tz().format() : '',
          Queue: JSON.stringify(Queue),
          Status: JSON.stringify(Status),
          PageCount: String(page),
          Descending: String(Descending),
          OrderBy: OrderBy,
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };
  const [taskList, setTaskList] = useState<ITasksData[]>([]);
  const searchTaskList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(prev => prev + 1);
    try {
      let page = 0;
      if (details?.isPrevious) {
        const currentPage = details?.currentPage ?? 0;
        page =
          currentPage % totalValueForPagination === 0
            ? currentPage / totalValueForPagination - 1
            : Math.floor(currentPage / totalValueForPagination);
      } else {
        page = details?.currentPage
          ? Math.floor(details?.currentPage / totalValueForPagination)
          : 0;
      }
      const payload = generatePayload();
      const response = await TaskService.getTaskList({
        ...payload,
        Page: page,
        Limit: limit * totalValueForPagination,
      });
      if (response?.status === 200) {
        if (response?.data?.Result?.length > 0) {
          if (details?.reset ?? true) {
            const data = response?.data?.Result.slice(0, limit);
            setTaskList(data);
          }
          setPaginationCommon(
            response?.data?.Result,
            details?.currentPage || 1,
            details?.reset ?? true,
            limit,
            totalValueForPagination,
            setIsMoreData,
            setPaginationData,
            setActive,
            setIsLastPage,
            page,
          );
        } else {
          setTaskList([]);
          setPaginationData(new Map());
        }
      } else {
        setTaskList([]);
      }
    } catch (error) {
      setTaskList([]);
    } finally {
      setLoading(prev => prev - 1);
    }
  };
  const handleSubmit = async () => {
    if (!selectTaskDetails?.selected?.ID) return;
    if (selectTaskDetails?.isDelete) {
      await deleteTask(selectTaskDetails?.selected?.ID);
    } else {
      await reattemptTask(selectTaskDetails?.selected?.ID);
    }
    reset();
  };
  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setWidth(newWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);
  useEffect(() => {
    searchTaskList();
  }, [filter, sideFormFilter, page, limit, timeZone]);

  return (
    <div className="task_container">
      <div className="task_top_container">
        <div className="task_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setFilter(pre => {
                return {
                  ...pre,
                  Page: 1,
                };
              });
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer
          className="tasks_table_container Common_Table"
          ref={ref}>
          <Table
            className="tasks_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                <TableCell></TableCell>
                {headCellList?.map(headCell => {
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {taskList.length > 0 ? (
                taskList.map((row, index) => {
                  return (
                    <>
                      <TableRow
                        hover
                        className="table_row"
                        role="checkbox"
                        tabIndex={-1}
                        key={index}>
                        <TableCell className="table_cell">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            className="expand-row"
                            onClick={() => handleRowToggle(index)}>
                            {(openRows ? openRows[index] : false) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        {headCellList.map(headCell => {
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      {(openRows ? openRows[index] : false) && (
                        <TableRow className="row_collapsed">
                          <TableCell
                            className="table_border_none nested-table-cell"
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={9}>
                            <Collapse
                              in={openRows ? openRows[index] : false}
                              timeout="auto"
                              unmountOnExit>
                              <div style={{ width: width ? width : 'auto' }}>
                                <NestedTableComponent row={row} />
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          loading={!!loading}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          searchApi={searchTaskList}
          setData={setTaskList}
          onPageChange={() => {
            setOpenRows([]);
          }}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
      <SideFilter
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        setLoading={setLoading}
        settaskList={setTaskList}
        searchtaskList={searchTaskList}
        setPage={setPage}
      />
      <ConfirmationDialog
        open={Boolean(
          selectTaskDetails?.isDelete || selectTaskDetails?.isReattempt,
        )}
        handleClose={reset}
        message={`${selectTaskDetails?.isDelete ? 'delete' : 'reattempt'} task`}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default Tasks;
