interface ToggleButtonProps {
  handleToggle: () => void;
  checked: boolean;
}
const ToggleButton = ({ handleToggle, checked }: ToggleButtonProps) => {
  return (
    <div
      className={`w-10 h-5 flex items-center bg-blue-100 rounded-full p-1 cursor-pointer transition-colors duration-300 ease-in-out ${
        checked ? 'bg-blue-400' : ''
      }`}
      onClick={handleToggle}>
      <div
        className={`bg-gray-100 w-4 h-4  rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
          checked ? 'translate-x-5' : ''
        }`}></div>
    </div>
  );
};

export default ToggleButton;
