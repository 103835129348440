import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import globalCustomersService from 'services/customerService';
import { showNotification } from 'helper/common/commonFunctions';
import { ICustomerUpdate } from 'interface/customerInterface';
import TextField from 'components/common/textField';
import { Select } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';

interface IProps {
  payload: ICustomerUpdate | null;
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
}

interface IBlacklist {
  type: string;
  other: string;
}

const blacklistType = [
  { label: 'Dispute', value: 'Dispute' },
  { label: 'Threat', value: 'Threat' },
  { label: 'Other', value: 'Other' },
];

const Blacklist: FC<IProps> = ({ payload, handleClose, onApiResponse }) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IBlacklist>({
    defaultValues: {
      type: '',
      other: '',
    },
  });
  const blacklistCustomer = async (data: IBlacklist) => {
    if (!payload) {
      return;
    }
    setLoading(true);
    if (data?.type) {
      payload.IsBlacklisted = true;
    }
    const response = await globalCustomersService.customerUpdate(payload);
    setLoading(false);
    if (response?.status === 200) {
      showNotification('success', 'Customer Updated Successfully');
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'blackList',
          type: 'submit',
          loading,
          disabled: loading,
        },
      ]}
      className="flex-container"
      heading={'Blacklist'}>
      <form
        id="blackList"
        onSubmit={handleSubmit(blacklistCustomer)}
        className="order-detail_refund flex flex-col gap-2 popup-section">
        <div className="fields">
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  onChange={onChange}
                  value={value}
                  label="Blacklist type"
                  options={blacklistType}
                  placeholder="other"
                />
              );
            }}
          />
        </div>
        <div className="fields">
          <Controller
            name="other"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="Other"
                  className="filter_form_input multiline popup-section"
                  placeholder="Enter"
                  multiline
                  minRows={3}
                  maxRows={5}
                />
              );
            }}
          />
        </div>
      </form>
    </PopUpModal>
  );
};

export default Blacklist;
