import React, { useMemo } from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { useEffect } from 'react';
import 'components/common/multiInput/style.scss';
import { Chip, ClickAwayListener } from '@mui/material';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface ICUstomizedProps {
  limits?: number;
  label: string;
  tagsValue: string[];
  placeholder?: string;
  validation?: { validationRule: RegExp; validationMessage: string }[];
  errorString?: string;
  onChange: (_value: string[]) => void;
  onClick?: () => void;
  onDelete?: (_index: number) => void;
  isLowerCase?: boolean;
  onInputChange?: (_value: string) => void;
  ishandleClickOutside?: boolean;
  setError?: (_value: string) => void;
  onKeyDown?: boolean;
  refForClearInput?: React.MutableRefObject<string>;
  tagAutoCompleteID?: string;
}
export function MultipleInputTag({
  onInputChange,
  limits = 1,
  label,
  tagsValue,
  placeholder = '',
  onChange,
  errorString,
  onClick,
  onDelete,
  isLowerCase = false,
  ishandleClickOutside = true,
  onKeyDown = true,
  validation,
  setError,
  refForClearInput = { current: '' },
  tagAutoCompleteID,
}: ICUstomizedProps) {
  const [limit, setLimit] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState('');
  const { getRootProps, getInputProps, getTagProps, focused, setAnchorEl } =
    useAutocomplete({
      id: tagAutoCompleteID ?? 'customInputInTag',
      defaultValue: [],
      multiple: true,
      options: [],
    });
  const deleteTag = (indexs: number) => {
    onChange(tagsValue.filter((_, index) => index !== indexs));
  };
  function handleClickOutside() {
    if (ishandleClickOutside && tag.trim().length != 0) {
      const isValidation =
        validation &&
        validation?.some(({ validationRule, validationMessage }) => {
          if (!validationRule?.test(tag)) {
            setError && setError(validationMessage);
            return true;
          }
        });
      if (!isValidation) {
        onChange([...tagsValue, tag]);
        setTag('');
      }
    }
    setLimit(true);
  }
  useEffect(() => {
    return () => {
      onChange([]);
    };
  }, []);

  useMemo(() => {
    if (refForClearInput.current === 'clear') {
      setTag('');
      refForClearInput.current = '';
    }
  }, [refForClearInput.current]);

  return (
    <div {...getRootProps()} className="common-multi-select-input">
      <label className="label">{label}</label>
      <ClickAwayListener
        onClickAway={() => {
          handleClickOutside();
        }}>
        <div
          ref={setAnchorEl}
          className={`inputWrapper ${focused ? 'focused' : ''}`}>
          {limit === false ? (
            <>
              {tagsValue?.map((option: string, index: number) => (
                <Chip
                  key={index}
                  label={option}
                  {...getTagProps}
                  onDelete={() => {
                    deleteTag(index);
                    onDelete && onDelete(index);
                  }}
                  className="custom_styled_tag"
                />
              ))}
            </>
          ) : (
            <>
              {tagsValue?.map((option: string, index: number) =>
                index < limits ? (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps}
                    onDelete={() => {
                      deleteTag(index);
                      onDelete && onDelete(index);
                    }}
                    className="custom_styled_tag"
                  />
                ) : (
                  <></>
                ),
              )}
              {tagsValue?.length - limits > 0 ? (
                <div className="addButton" onClick={() => setLimit(false)}>
                  +{tagsValue.length - limits}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          <input
            onClick={onClick}
            className="text_field_input_multiInput"
            placeholder={placeholder}
            {...getInputProps()}
            value={tag}
            {...(onKeyDown && {
              onKeyDown: e => {
                if (e.key === 'Enter' && tag.trim().length != 0) {
                  e.preventDefault();
                  const isValidation =
                    validation &&
                    validation?.some(
                      ({ validationRule, validationMessage }) => {
                        if (!validationRule?.test(tag)) {
                          setError && setError(validationMessage);
                          return true;
                        }
                      },
                    );
                  if (!isValidation) {
                    onChange([...tagsValue, tag]);
                    setTag('');
                  }
                }
              },
            })}
            onChange={e => {
              setLimit(false);
              setTag(
                isLowerCase ? e.target?.value?.toLowerCase() : e.target?.value,
              );
              if (errorString) setError && setError('');
              onInputChange && onInputChange(e.target.value);
            }}
          />
        </div>
      </ClickAwayListener>
      {errorString ? <p className="error_message">{errorString}</p> : <></>}
    </div>
  );
}

interface IControlledMultiInputTags
  extends Omit<ICUstomizedProps, 'tagsValue' | 'errorString' | 'onChange'> {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledMultipleInputTag = ({
  name,
  rules,
  ...rest
}: IControlledMultiInputTags) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, ...restFields }, fieldState: { error } }) => {
        return (
          <MultipleInputTag
            {...rest}
            {...restFields}
            tagsValue={value}
            errorString={error?.message}
          />
        );
      }}
    />
  );
};
