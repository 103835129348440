import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';
import { PrivateWrapper } from 'router/PrivateWrapper';
import { Path, pageNames } from 'router/constant';
import { PublicWrapper } from 'router/PublicWrapper';
import { SideNavigation } from 'layouts/SideNavigation';
import DashboardPage from 'pages/dashboard';
import LoginPage from 'pages/login';
import { RootState } from 'redux-setup/store';
import { useAppSelector } from 'hooks/reduxHooks';
import CartDetails from 'components/abandonedCarts/cartDetails';
import { PageNotFound } from 'shared-components';
import TransactionsPage from 'pages/transactions';
import CustomerPage from 'pages/customers';
import CustomerDetailsPage from 'pages/customerDetails';
import RententionReportPage from 'pages/retentionReport';
// for future use
// import SubscriptionPage from '../pages/subscription';
import OrderPage from 'pages/orders';
import OrderDetailsPage from 'pages/orderDetails';
import AbandonedCartsPage from 'pages/abandonedCarts';
import SubscriptionManagement from 'components/SubscriptionManagement';
import StoreFrontEvent from 'pages/storeFrontEvents';
import OnboardingPage from 'pages/onboarding';
import InvitesPage from 'pages/invites';
import RiskPage from 'pages/risk';
import RestrictRoute from 'router/RestrictRoute';
import SubscriptionSalvagePage from 'pages/subscriptionSalvage';
import SubscriptionProjectionPage from 'pages/subscriptionProjection';
import CascadePage from 'pages/cascade';
import TransactionsLogPage from 'pages/transactionLog';

// import Home from 'page-builder/pages';
import Home from 'components/page-builder/home';
import { PuckWrapper } from './PuckWrapper';
import { StagingBanner } from 'components/banner/StagingBanner';
import Setting from 'components/setting';
import SotreDetailsPage from 'pages/storeDetailsPage';
import ShopifyDetailsPage from 'pages/shopifyDetails';
import InviteUser from 'components/inviteUser';
import Admins from 'pages/admins';
import StoreOverview from 'pages/storeOverview';
import { useEffect } from 'react';
import CommonWrapper from './CommonWrapper';
import MembershipOfferPage from 'pages/MembershipOffersPage';
import AddMembershipOffersPage from 'pages/MembershipOffersPage/AddMembershipOffer.tsx';
import SettingsPage from 'pages/SettingsPage';
import MembershipPage from 'pages/membership';
import AddMembershipPage from 'pages/membership/AddMembershipPage';
import ProductsPage from 'pages/products';
import { ShopifyWrapper } from './ShopifyWrapper';
import ShopifyStoresPage from 'components/shopify_app_components/store';
import CreateStore from 'components/shopify_app_components/store/createStore';
import CreateStoreFront from 'components/shopify_app_components/storefront/createStorefront';
import { Path as CheckoutPath } from 'shared-components';
import SubscriptionEvents from 'components/subscriptionEvents';
import BillingSummary from 'pages/billingSummary';
import Tasks from 'pages/tasks';

interface IProps {
  setTheme: (_theme: boolean) => void;
}
const AppRouter = ({ setTheme }: IProps) => {
  const token = useAppSelector((state: RootState) => state.user.token);
  const theme = useAppSelector((state: RootState) => state.theme.theme);
  const shopifyToken = useAppSelector(
    (state: RootState) => state.shopify.token,
  );
  const isOnboarding = useAppSelector(
    (state: RootState) => state.user.isOnboarding,
  );
  const { customer, order, subscription, transaction, events, superAdmin } =
    useAppSelector((state: RootState) => state.permission);

  useEffect(() => {
    setTheme(theme || !!shopifyToken);
    if (theme || shopifyToken) {
      document.querySelector('body')?.classList.add('light');
    } else {
      document.querySelector('body')?.classList.remove('light');
    }
  }, [theme, shopifyToken]);

  return (
    <BrowserRouter>
      <StagingBanner />
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                token
                  ? isOnboarding
                    ? Path.ON_BOARDING
                    : shopifyToken
                      ? Path.SHOPIFY_SETTINGS
                      : Path.DASHBOARD
                  : Path.LOGIN
              }
            />
          }
        />
        <Route element={<CommonWrapper />}>
          <Route element={<PrivateWrapper auth={{ isAuthenticated: token }} />}>
            {!shopifyToken ? (
              <>
                {!isOnboarding && (
                  <>
                    <Route path={Path.APP} element={<SideNavigation />}>
                      <Route
                        path={Path.DASHBOARD}
                        element={
                          <RestrictRoute pageName={pageNames.DASHBOARD}>
                            <DashboardPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.TRANSACTIONS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.TRANSACTION}
                            permission={transaction}>
                            <TransactionsPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.TRANSACTION_LOG}
                        element={
                          <RestrictRoute
                            pageName={pageNames.TRANSACTION_LOG}
                            permission={transaction}>
                            <TransactionsLogPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.BILLING_SUMMARY}
                        element={
                          <RestrictRoute
                            pageName={pageNames.BILLING_SUMMARY}
                            permission={superAdmin ? 'superAdmin' : ''}>
                            <BillingSummary />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.CUSTOMERS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.CUSTOMER}
                            permission="customer">
                            <CustomerPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.ORDERS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.ORDER}
                            permission={order}>
                            <OrderPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.ABANDONED_CARTS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.ABANDONED_CARTS}
                            permission={order}>
                            <AbandonedCartsPage />
                          </RestrictRoute>
                        }
                      />

                      <Route
                        path={Path.STORE_FRONT_EVENTS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.STOREFRONT_EVENTS}
                            permission={events}>
                            <StoreFrontEvent />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.ADMIN_USERS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.ADMIN_USERS}
                            permission={superAdmin ? 'superAdmin' : ''}>
                            <Admins />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.STORE_OVERVIEW}
                        element={
                          <RestrictRoute
                            pageName={pageNames.STORE_OVERVIEW}
                            permission={superAdmin ? 'superAdmin' : ''}>
                            <StoreOverview />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.TASKS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.TASKS}
                            permission={superAdmin ? 'superAdmin' : ''}>
                            <Tasks />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.SETTING}
                        element={
                          <RestrictRoute pageName={pageNames.SETTING}>
                            <Setting />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.INVITES}
                        element={
                          <RestrictRoute pageName={pageNames.INVITES}>
                            <InvitesPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.CUSTOMER_DETAILS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.CUSTOMER_DETAILS}
                            permission={customer}>
                            <CustomerDetailsPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.SUBSCRIPTION_EVENTS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.SUBSCRIPTION_EVENTS}
                            permission={order}>
                            <SubscriptionEvents />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.SUBSCRIPTION}
                        element={<Navigate to={Path.MANAGEMENT} />}></Route>
                      {/* for future */}
                      <Route
                        path={Path.PROJECTIONS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.SUBSCRIPTION_PROJECTION}
                            permission={subscription}>
                            <SubscriptionProjectionPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.MANAGEMENT}
                        element={
                          <RestrictRoute
                            pageName={pageNames.SUBSCRIPTION_MANAGEMENT}
                            permission={subscription}>
                            <SubscriptionManagement />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={`${Path.STORE_DETAILS}`}
                        element={
                          <RestrictRoute pageName={pageNames.STORE_DETAILS}>
                            <SotreDetailsPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={`${Path.SHOPIFY_DETAILS}`}
                        element={
                          <RestrictRoute pageName={pageNames.SHOPIFY_DETAILS}>
                            <ShopifyDetailsPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={`${Path.ORDER_DETAILS}`}
                        element={
                          <RestrictRoute
                            pageName={pageNames.ORDER_DETAILS}
                            permission={order}>
                            <OrderDetailsPage />
                          </RestrictRoute>
                        }
                      />
                      <Route
                        path={Path.CART_DETAILS}
                        element={
                          <RestrictRoute
                            pageName={pageNames.CART_DETAILS}
                            permission={order}>
                            <CartDetails />
                          </RestrictRoute>
                        }
                      />
                      <Route path={Path.REPORTING} element={<Outlet />}>
                        <Route
                          path={Path.RETENTION_REPORT}
                          element={
                            <RestrictRoute
                              pageName={pageNames.RETENTION_REPORT}>
                              <RententionReportPage />
                            </RestrictRoute>
                          }
                        />
                        <Route
                          path={Path.SUBSCRIPTION_SALVAGE}
                          element={
                            <RestrictRoute
                              pageName={pageNames.SUBSCRIPTION_SALVAGE}>
                              <SubscriptionSalvagePage />
                            </RestrictRoute>
                          }
                        />
                        <Route
                          path={`${Path.RISK}`}
                          element={
                            <RestrictRoute pageName={pageNames.RISK}>
                              <RiskPage />
                            </RestrictRoute>
                          }
                        />
                      </Route>
                      <Route
                        path={Path.CASCADING}
                        element={
                          <RestrictRoute pageName={pageNames.CASCADING}>
                            <CascadePage />
                          </RestrictRoute>
                        }
                      />
                    </Route>
                    <Route
                      path={`${Path.APP}/*`}
                      element={
                        <Navigate to={Path.NOT_FOUND_PAGE} replace={true} />
                      }
                    />
                    {/* <Route
                      path="/*"
                      element={
                        <Navigate to={Path.NOT_FOUND_PAGE} replace={true} />
                      }
                    /> */}
                  </>
                )}

                {/* Page Builder routes */}
                <Route element={<PuckWrapper />}>
                  <Route
                    path={CheckoutPath.PAGE_BUILDER_EDIT}
                    element={
                      <RestrictRoute pageName={pageNames.PAGE_BUILDER_EDIT}>
                        <Home />
                      </RestrictRoute>
                    }
                  />
                  <Route
                    path={CheckoutPath.PAGE_BUILDER_VIEW}
                    element={
                      <RestrictRoute pageName={pageNames.PAGE_BUILDER_VIEW}>
                        <Home />
                      </RestrictRoute>
                    }
                  />
                </Route>
                <Route
                  path={Path.INVITE_USER}
                  element={
                    <RestrictRoute pageName={pageNames.INVITE_USERS}>
                      <InviteUser />
                    </RestrictRoute>
                  }
                />
              </>
            ) : (
              <Route
                path={Path.SHOPIFY_APP}
                element={
                  <ShopifyWrapper auth={{ isAuthenticated: shopifyToken }} />
                }>
                <Route path={Path.MEMBERSHIP} element={<MembershipPage />} />
                <Route path={Path.PRODUCTS} element={<ProductsPage />} />
                <Route
                  path={Path.ADD_MEMBERSHIP}
                  element={<AddMembershipPage />}
                />
                <Route
                  path={Path.EDIT_MEMBERSHIP}
                  element={<AddMembershipPage />}
                />
                <Route
                  path={Path.MEMBERSHIP_OFFER}
                  element={<MembershipOfferPage />}
                />
                <Route
                  path={Path.ADD_MEMBERSHIP_OFFER}
                  element={<AddMembershipOffersPage />}
                />
                <Route
                  path={Path.MEMBERSHIP_ADD_OFFER}
                  element={<AddMembershipOffersPage />}
                />
                <Route
                  path={Path.SHOPIFY_SETTINGS}
                  element={<SettingsPage />}
                />
                <Route
                  path={Path.SHOPIFY_SETTINGS_PAGE}
                  element={<ShopifyStoresPage />}
                />
                <Route
                  path={Path.SHOPIFY_STORE_CREATE}
                  element={<CreateStore />}
                />
                <Route
                  path={`${Path.SHOPIFY_STORE_DETAILS}`}
                  element={<SotreDetailsPage />}
                />
                <Route
                  path={`${Path.SHOPIFY_STOREFRONT_CREATE}`}
                  element={<CreateStoreFront />}
                />
              </Route>
            )}
            <Route path={Path.ON_BOARDING} element={<OnboardingPage />} />
          </Route>
          <Route element={<PublicWrapper auth={{ isAuthenticated: token }} />}>
            <Route path={Path.LOGIN} element={<LoginPage />} />
          </Route>
          <Route
            path="/*"
            element={
              token ? (
                <PageNotFound />
              ) : (
                <Navigate to={Path.LOGIN} replace={true} />
              )
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
