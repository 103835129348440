import { useState, useEffect } from 'react';
import { ComponentConfig, usePuck } from '@measured/puck';
import { DropZone } from '@measured/puck';
import { CartTotal } from '../../../../page-builder/components/cart/cart-total/CartTotal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import {
  fontField,
  FontProps,
} from '../../../../page-builder/puck/reusable-props/font';
import { ReduxSelectorProps } from '../../../services/constants';
import CustomTooltip from '../../../../components/common/tooltip';
import InputField from '../../../../components/common/input-field';
import { Path } from '../../../utils/constants';
import {
  ShowDropDownProps,
  showDropDownFields,
} from 'shared-components/src/page-builder/puck/reusable-props/showDropDown';

export type StandardLayoutProps = {
  border: boolean;
  bgColor: string;
  bgColorLeft: string;
  bgColorRight: string;
  borderColor: string;
  orderSummaryText: string;
  orderSummaryTextColor: string;
  orderSummaryCartTotal: boolean;
} & FontProps &
  ShowDropDownProps;

export const StandardLayout = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<StandardLayoutProps> => {
  return {
    label: (
      <CustomTooltip title="StandardLayout">
        <span>Standard Layout</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showDropDownFields,
      border: {
        type: 'radio',
        label: 'Border',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      borderColor: {
        type: 'custom',
        label: 'Border Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="BorderColor">
                  <span>Border Color</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColor: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="backgroundColor">
                  <span>Background Color</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorLeft: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="Background Color Left">
                  <span>Background Color(Left Column)</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorRight: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="Background Color Right">
                  <span>Background Color(Right Column)</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryText: {
        type: 'custom',
        label: 'Order Summary Text',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      orderSummaryTextColor: {
        type: 'custom',
        label: 'Summary Text Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="Text Color">
                  <span>Text Color</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryCartTotal: {
        type: 'radio',
        label: 'Mobile Summary Cart Total',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      ...fontField,
    },
    defaultProps: {
      showDropDown: { tablet: true, mobile: true },
      border: true,
      borderColor: '#E5E5E5',
      bgColor: '#FFFFFF',
      bgColorLeft: '#FFFFFF',
      bgColorRight: '#F9F9F9',
      orderSummaryCartTotal: true,
      orderSummaryTextColor: '#000',
      orderSummaryText: 'Show order summary',
      font: {
        size: 'text-sm',
        weight: 'font-bold',
      },
    },
    render: ({
      border,
      bgColorLeft,
      bgColorRight,
      borderColor,
      bgColor,
      orderSummaryText,
      orderSummaryCartTotal,
      orderSummaryTextColor,
      showDropDown = { tablet: true, mobile: true },
    }) => {
      const [dropDown, setDropDown] = useState(false);
      const [total, setTotal] = useState(true);
      const { pathname } = useLocation();
      const toggleDropZone = () => setDropDown(!dropDown);
      const puck = usePuck();

      useEffect(() => {
        const handleResize = () => {
          let width = window.innerWidth;
          if (pathname.includes(Path.PAGE_BUILDER_EDIT)) {
            width = puck.appState.ui.viewports.current.width;
          }
          if (width >= 1024) {
            setDropDown(true);
          } else if (width >= 768) {
            setDropDown(showDropDown.tablet);
          } else {
            setDropDown(showDropDown.mobile);
          }
          setTotal(width <= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [showDropDown, pathname, puck.appState.ui.viewports.current.width]);

      return (
        <>
          <div
            className={`split-layout-grid ${border ? 'lg:border-t-2' : ''} mx-auto max-w-full lg:max-w-none`}
            style={{
              borderColor: borderColor,
              backgroundColor: bgColor,
            }}>
            <div
              className={`split-layout-child mx-auto grid gap-x-8 grid-cols-1 lg-min:grid-cols-[1fr_0.8fr] grid-rows-[min-content_1fr] sm:max-w-8xl lg:max-w-6xl xl:max-w-6xl pb-14 lg:px-8`}
              style={{ width: '100%' }}>
              <div
                className="header-dropdzone px-4"
                style={{
                  backgroundColor: bgColorLeft,
                }}>
                <DropZone zone="top" />
              </div>
              <div
                className={`split-layout-child md:row-span-2 ${border ? 'lg-max:border-l-0 border-l-2' : ''} px-4 py-4`}
                style={{
                  backgroundColor: bgColorRight,
                  height: 'fit-content',
                }}>
                <div className="flex justify-between lg:hidden">
                  <button
                    type="button"
                    className={`flex items-center`}
                    style={{ color: orderSummaryTextColor }}
                    onClick={toggleDropZone}>
                    {orderSummaryText}
                    <ChevronDownIcon
                      className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                        dropDown ? 'transform rotate-180' : ''
                      }`}
                      aria-hidden="true"
                    />
                  </button>
                  {total && orderSummaryCartTotal && (
                    <CartTotal useAppSelector={useAppSelector} />
                  )}
                </div>
                {(dropDown || window.innerWidth >= 1024) && (
                  <div
                    className={`transition-all duration-300 ${dropDown ? 'max-h-[100%] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                    <DropZone zone="right" />
                  </div>
                )}
              </div>
              <div
                className="px-4"
                style={{
                  backgroundColor: bgColorLeft,
                }}>
                <DropZone zone="left" />
              </div>
            </div>
          </div>
        </>
      );
    },
  };
};
