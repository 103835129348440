import { FC, useState } from 'react';
import './style.scss';
import { showNotification } from 'helper/common/commonFunctions';
import { ICustomerDetails } from 'interface/customerInterface';
import globalCustomerService from 'services/customerService';
import TagAction from 'components/Actions/tags';
import CancelSubscriptionAction from 'components/Actions/cancelSubscription';
import BlackList from 'components/Actions/blacklist';
import ChangeSubscription from 'components/Actions/changeSubscription';
import ActiveSubscriptionAction from 'components/Actions/activeSubscription/index';
import PauseSubscriptionAction from 'components/Actions/pauseSubscription/index';

interface IProps {
  filterList: string[] | undefined;
  selected: Map<string, ICustomerDetails>;
  searchCustomerList: () => void;
  setCustomersList: React.Dispatch<React.SetStateAction<ICustomerDetails[]>>;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  resetSelected: () => void;
}

const ActionFilter: FC<IProps> = ({
  filterList,
  selected,
  setCustomersList,
  setLoading,
  searchCustomerList,
  resetSelected,
}) => {
  const [isPopupOpen, setPopupOpen] = useState<{
    tag: boolean;
    blacklist: boolean;
    cancel_subscription: boolean;
    change_subscription: boolean;
    resume_subscription: boolean;
    pause_subscription: boolean;
  }>({
    tag: false,
    blacklist: false,
    cancel_subscription: false,
    change_subscription: false,
    resume_subscription: false,
    pause_subscription: false,
  });
  const [textValue, setTextValue] = useState<string[]>([]);
  const handleClose = (value: string) => {
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };
  const handleOpen = (value: string) => {
    value = value.toLowerCase().replaceAll(' ', '_');
    if (!selected.size) {
      showNotification('failed', 'Please select at least one Customer');
      return;
    }
    if (selected.size > 1) {
      showNotification(
        'failed',
        'This Action does not compatible for multiple rows',
      );
      return;
    }

    if (value === 'tag') {
      const tags: string[] =
        (selected.values().next().value as ICustomerDetails).Tags || [];
      setTextValue(tags);
    }
    setPopupOpen(pre => ({ ...pre, [value]: true }));
  };

  const getSubscriptionPayload = () => {
    const { Subscription, Store } = [...selected.values()]?.[0] || {};
    const {
      ID,
      Version,
      Amount,
      Frequency,
      Interval,
      NextBillingDate,
      Status,
    } = Subscription || {};

    const { ID: storeID } = Store || {};
    return {
      Amount,
      Frequency,
      ID,
      Interval: Number(Interval),
      NextBillingDate,
      Status: Status,
      StoreID: storeID,
      Version: Number(Version),
    };
  };

  const updateCustomerTag = async (textTag?: string | null) => {
    setLoading(pre => pre + 1);
    const selectedCustomer = [...selected.values()][0];
    const updateCustomerTagResponse =
      await globalCustomerService.customerUpdate({
        Email: selectedCustomer?.Email,
        HasDisputed: selectedCustomer?.HasDisputed,
        ID: selectedCustomer?.ID,
        IsBlacklisted: selectedCustomer?.IsBlacklisted,
        PhoneNumber: selectedCustomer?.PhoneNumber,
        StoreID: selectedCustomer?.Store?.ID,
        Tags: [...textValue, ...(textTag ? [textTag] : [])],
        Version: selectedCustomer?.Version,
      });

    if (updateCustomerTagResponse?.status === 200) {
      showNotification('success', 'Customer Updated Successfully');
      // updating the customer tags manualy if the API is success
      searchCustomerList();
      setCustomersList(pre =>
        pre.map(item => {
          if (item.ID === selectedCustomer.ID) {
            return {
              ...item,
              tags: textValue.length > 0 ? textValue : item.Tags,
              version: item?.Version + 1,
            };
          }
          return item;
        }),
      );
    }
    resetSelected();
    setLoading(pre => pre - 1);
  };

  return (
    <div className="action_filter_container">
      {isPopupOpen.tag && selected?.size && (
        <TagAction
          open={isPopupOpen.tag}
          handleClose={() => {
            handleClose('tag');
          }}
          updateFunction={value => {
            updateCustomerTag(value);
          }}
          setTextValue={setTextValue}
          textValue={textValue}
          tagAutoCompleteID="customerTag"
        />
      )}
      {isPopupOpen?.blacklist && (
        <BlackList
          payload={(() => {
            const customer = [...selected.values()]?.[0] || {};
            return {
              Email: customer?.Email,
              HasDisputed: customer?.HasDisputed,
              ID: customer?.ID,
              IsBlacklisted: customer?.IsBlacklisted,
              PhoneNumber: customer?.PhoneNumber,
              StoreID: customer?.Store?.ID,
              Tags: customer?.Tags ? customer.Tags : [],
              Version: customer?.Version,
            };
          })()}
          handleClose={() => {
            handleClose('blacklist');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              searchCustomerList();
              setCustomersList(pre =>
                pre?.map(item => {
                  if (item.ID === [...selected.values()]?.[0]?.ID) {
                    return {
                      ...item,
                      is_blacklisted: true,
                      version: item?.Version + 1,
                    };
                  }
                  return item;
                }),
              );
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen?.cancel_subscription && (
        <CancelSubscriptionAction
          handleClose={() => {
            handleClose('cancel_subscription');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('cancel_subscription');
            }
            resetSelected();
            searchCustomerList();
          }}
          payload={getSubscriptionPayload()}
        />
      )}
      {isPopupOpen?.change_subscription && (
        <ChangeSubscription
          handleClose={() => {
            setPopupOpen(pre => ({ ...pre, change_subscription: false }));
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_subscription');
            }
            searchCustomerList();
            resetSelected();
          }}
          payload={getSubscriptionPayload()}
        />
      )}
      {isPopupOpen.resume_subscription && (
        <ActiveSubscriptionAction
          payload={getSubscriptionPayload()}
          handleClose={() => {
            setPopupOpen(pre => ({ ...pre, resume_subscription: false }));
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              searchCustomerList();
            }
            resetSelected();
          }}
        />
      )}
      {isPopupOpen.pause_subscription && (
        <PauseSubscriptionAction
          payload={getSubscriptionPayload()}
          handleClose={() => {
            setPopupOpen(pre => ({ ...pre, pause_subscription: false }));
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              searchCustomerList();
            }
            resetSelected();
          }}
        />
      )}
      {filterList?.map((cols: string, index: number) => (
        <div
          className="action_filter_columns"
          key={index}
          onClick={() => {
            handleOpen(cols);
          }}>
          {cols}
        </div>
      ))}
    </div>
  );
};

export default ActionFilter;
