import { ReactNode, useEffect, useState } from 'react';
import { IDeleteInvites, IInvitation } from 'interface/userInterface';
import {
  formattedDate,
  getTotalPages,
  listMyPermissions,
  setPaginationCommon,
} from 'utils/helper';
import StatusBadge from 'components/common/statusBadge';
import userService, { IGetInvites } from 'services/userService';
import './style.scss';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setMyInvitationDetails } from 'redux-setup/slices/InvitationSlice';
import { showNotification } from 'helper/common/commonFunctions';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import PaginationCommon from 'components/common/PaginationCommon';
export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IInvitation) => ReactNode;
  hide?: boolean;
  valueGetter?: (_row: IInvitation) => string | number;
}
const filterActionList = {
  delete: ['Delete'],
  all: ['Accept', 'Decline', 'Delete'],
  none: [],
};

const filteredActions = (status: string) => {
  if (!status) return filterActionList.none;
  const checkStatus = ['accepted', 'declined'].includes(status);
  return checkStatus ? filterActionList.delete : filterActionList.all;
};

export default function MyInvites() {
  const [limt, setLimit] = useState<number>(25);
  const dispatch = useAppDispatch();
  const { myInvitesList } = useAppSelector(state => state.invitation);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [paginationMap, setPaginationMap] = useState<
    Map<number, IInvitation[]>
  >(new Map());
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = (): void => {
    setAnchorEl(null);
  };
  const [filter] = useState<IGetInvites>({
    Descending: false,
    Limit: 25,
    OrderBy: 'CreatedAt',
    Page: 0,
  });

  const headCellList: readonly HeadCell[] = [
    {
      id: 'date',
      label: 'Date',
      hide: false,
      cellRender: row => {
        return (
          <Tooltip title={formattedDate(row?.CreatedAt)} placement="top" arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'name',
      label: 'Store Name',
      hide: false,
      cellRender: row => {
        return <span>{row?.Store?.Name}</span>;
      },
      valueGetter: row => {
        return row?.Store?.Name ?? '';
      },
    },
    {
      id: 'email',
      label: 'Email',
      hide: false,
      cellRender: row => {
        return <span>{row?.Email}</span>;
      },
      valueGetter: row => {
        return row?.Email ?? '';
      },
    },
    {
      id: 'status',
      label: 'Status',
      hide: false,
      cellRender: row => {
        return <StatusBadge status={row.Status} />;
      },
    },
    {
      id: 'permissions',
      label: 'Permissions',
      hide: false,
      cellRender: row => {
        return (
          <div className="tags_wrapper">
            {row?.Permissions?.slice(0, 2).map((permission, key) => (
              <span key={key} className="tag">
                {permission.replace(':', ': ')}
              </span>
            ))}
            {row?.Permissions?.length > 2 && (
              <>
                <span
                  id={row.ID}
                  className="tag"
                  onMouseEnter={handleOpenPopover}
                  onMouseLeave={handleClosePopover}>
                  +{row.Permissions.length - 2}
                </span>
                {anchorEl && anchorEl?.id == row?.ID && (
                  <Popover
                    anchorEl={anchorEl}
                    className="invites-popover"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorEl)}>
                    <div className="invite-permissions">
                      {row?.Permissions?.map(
                        (permission: string, index: number) => {
                          return (
                            <p key={index}>{permission.replace(':', ': ')}</p>
                          );
                        },
                      )}
                    </div>
                  </Popover>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="action-invites">
            {filteredActions(row.Status).map((action, index) => (
              <button
                key={index}
                onClick={() => {
                  setOpenDialog(action);
                  setSelectedData(row);
                }}
                className={`button ${action}`}>
                <Tooltip title={action} placement="top">
                  {action === 'Accept' ? (
                    <CheckIcon />
                  ) : action === 'Decline' ? (
                    <CloseIcon />
                  ) : (
                    <DeleteIcon />
                  )}
                </Tooltip>
              </button>
            ))}
          </div>
        );
      },
    },
  ];

  const [selected, setSelected] = useState<Map<string, IInvitation>>(new Map());

  const [selectedData, setSelectedData] = useState<IInvitation>(
    {} as IInvitation,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<string>('');
  const closeDialogs = () => {
    setOpenDialog('');
  };

  const getUserInvites = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(true);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const res = await userService.getInvites({
      ...filter,
      Page: page,
      Limit: limt * totalValueForPagination,
    });
    if (res.status === 200) {
      if (res?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = res?.data?.Result.slice(0, limt);
          dispatch(
            setMyInvitationDetails({
              myInvitesList: data,
            }),
          );
        }
        setPaginationCommon(
          res?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limt,
          totalValueForPagination,
          setIsMoreData,
          setPaginationMap,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        dispatch(setMyInvitationDetails({ myInvitesList: res?.data?.Result }));
      }
      setSelected(new Map());
    } else {
      dispatch(setMyInvitationDetails({ myInvitesList: res?.data?.Result }));
    }
    setLoading(false);
  };

  const acceptInvite = async () => {
    const data = {
      StoreID: selectedData.Store.ID,
      InviteID: selectedData.ID,
    };
    setLoading(true);
    const res = await userService.acceptInvite(data);
    if (res.status === 200) {
      showNotification('success', 'Invite accepted successfully');
      getUserInvites();
      listMyPermissions();
      closeDialogs();
    }
    setLoading(false);
  };

  const declineInvite = async () => {
    const data = {
      StoreID: selectedData.Store.ID,
      InviteID: selectedData.ID,
    };
    setLoading(true);
    const res = await userService.declineInvite(data);
    if (res.status === 200) {
      showNotification('success', 'Invite declined successfully');
      getUserInvites();
      closeDialogs();
    }
    setLoading(false);
  };

  const deleteInvite = async () => {
    const payload: IDeleteInvites = {
      InviteIDs: [selectedData.ID],
      StoreID: selectedData.Store.ID,
    };

    setLoading(true);
    const res = await userService.deleteInvite(payload);
    if (res.status === 200 || res.status === 204) {
      showNotification('success', 'Invite deleted successfully');
      getUserInvites();
      closeDialogs();
    }
    setLoading(false);
  };
  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };
  useEffect(() => {
    getUserInvites();
  }, [limt]);

  return (
    <>
      <div className="invites">
        <TableContainer className="Common_Table">
          <Table
            className="orders_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell
                      className="table_header_cell header_text"
                      key={headCell.label}
                      id={headCell.label}>
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {myInvitesList?.length > 0 ? (
                myInvitesList?.map(row => {
                  const isItemSelected = !!selected.get(row?.ID);
                  return (
                    <TableRow
                      hover
                      className="table_row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_cell "
                            key={headCell.label}
                            component="th"
                            id={headCell.label}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : headCell?.valueGetter
                                ? headCell?.valueGetter(row)
                                : get(row, headCell.id)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="!py-20 !left-[50%] pointer-events-none">
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          loading={!!loading}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limt}
          paginationData={paginationMap}
          setPaginationData={setPaginationMap}
          searchApi={getUserInvites}
          setData={setMyInvitationDetails}
          setReduxData={val => {
            dispatch(setMyInvitationDetails(val));
          }}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={loading} />
      </div>

      <ConfirmationDialog
        open={openDialog === 'Accept'}
        message={'accept invite'}
        handleClose={closeDialogs}
        onSubmit={acceptInvite}
        loading={loading}
      />
      <ConfirmationDialog
        open={openDialog === 'Decline'}
        message={'decline invite'}
        handleClose={closeDialogs}
        onSubmit={declineInvite}
        loading={loading}
      />
      <ConfirmationDialog
        open={openDialog === 'Delete'}
        message={'delete invite'}
        handleClose={closeDialogs}
        onSubmit={deleteInvite}
        loading={loading}
      />
    </>
  );
}
