import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Button } from 'shared-components';
import { IRiskFormFilter } from 'interface/riskInterface';
import { FC } from 'react';
import { SelectControl } from 'shared-components';
import { transactionTypeOptions } from 'components/dashboard/constant';
import { cardBrandOptions } from 'components/risk/constant';
import { defaultOrderFilterValues } from 'components/risk';
import MerchantSelect from 'components/common/MerchantSelect';

interface IProps {
  sideFormFilter: IRiskFormFilter;
  filterSubmission: (_data: IRiskFormFilter) => void;
  loading: number;
}

const FilterForm: FC<IProps> = ({
  sideFormFilter,
  filterSubmission,
  loading,
}) => {
  const methods = useForm<IRiskFormFilter>({
    defaultValues: sideFormFilter,
  });
  const { handleSubmit, control, reset } = methods;
  const onSubmit = (data: IRiskFormFilter) => {
    filterSubmission(data);
  };

  return (
    <div className="order-filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <FormProvider {...methods}>
          <Controller
            name="ExternalProcessorID"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <MerchantSelect
                  label="External Processor ID"
                  placeholder="Type External Processor ID"
                  onChange={val => {
                    onChange(val || null);
                  }}
                  value={value || null}
                />
              );
            }}
          />
          <SelectControl
            name="cardBrand"
            label="Card Brand"
            options={cardBrandOptions}
            placeholder="Select"
          />
          <SelectControl
            name="transactiontype"
            label="Transaction Type"
            options={transactionTypeOptions}
            placeholder="Select"
          />
          <div className="button_wrapper">
            <Button
              className="transaction_for_submit white-space-nowrap"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="transaction_for_submit white-space-nowrap"
              onClick={() => {
                if (!loading) {
                  reset(defaultOrderFilterValues);
                }
              }}
              label="Clear Filter"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
export default FilterForm;
