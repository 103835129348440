import { FC, useState } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button } from 'shared-components';
import dayjs from 'dayjs';
import { ControlledDatePicker } from 'components/common/datePicker';
import './style.scss';
import { getDateFilter } from 'utils/helper';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { dateRangeOptions } from 'components/dashboard/constant';
import { IDefaultOption, ITaskFormFilter } from 'interface/tasks';
import { dateRange } from 'components/dashboard-2/constant';
import { defaultTaskValues } from 'components/tasks';
import MultiSelect from 'components/common/multiSelect';
import { queueData, statusOption } from 'utils/constants';

interface IProps {
  sideFilter: ITaskFormFilter;
  filterSubmission: (_data: ITaskFormFilter) => void;
  setPage: (_page: number) => void;
}

const FilterForm: FC<IProps> = ({ sideFilter, filterSubmission, setPage }) => {
  const [isCustomRange, setCustomRange] = useState<boolean>(
    !!sideFilter.DateRange,
  );
  const methods = useForm<ITaskFormFilter>({
    defaultValues: sideFilter,
  });
  const { handleSubmit, reset, setValue, control, watch } = methods;

  const onSubmit = (data: ITaskFormFilter) => {
    filterSubmission(data);
    setPage(1);
  };

  return (
    <div className="task-event-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <FormProvider {...methods}>
          <Controller
            name="Queue"
            control={control}
            render={({ field: { ref, value, onChange } }) => {
              return (
                <MultiSelect<IDefaultOption>
                  value={value || []}
                  options={queueData}
                  labelKey="label"
                  valueKey="value"
                  label="Queue"
                  placeholder="Select Queue"
                  ref={ref}
                  limitTags={1}
                  onChange={(newValue: IDefaultOption[]) => {
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
          <Controller
            name="Status"
            control={control}
            render={({ field: { ref, value, onChange } }) => {
              return (
                <MultiSelect<IDefaultOption>
                  value={value || []}
                  options={statusOption}
                  labelKey="label"
                  valueKey="value"
                  label="Payment Status"
                  placeholder="Select Status"
                  ref={ref}
                  limitTags={1}
                  onChange={(newValue: IDefaultOption[]) => {
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
          <Controller
            name="DateRange"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const handleChange = (e: string) => {
                if (e !== 'Custom Range') {
                  const { start_time, end_time } = getDateFilter(e);
                  setValue('StartTime', start_time);
                  setValue('EndTime', end_time);
                } else {
                  setValue('StartTime', null);
                  setValue('EndTime', null);
                }
                setCustomRange(true);
                onChange(e);
              };
              return (
                <AutoCompleteSelect
                  options={dateRangeOptions}
                  labelKey="label"
                  onChange={val => handleChange(val?.value)}
                  value={
                    dateRangeOptions?.find(type => type.value === value) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                  label="Date Range"
                />
              );
            }}
          />
          {isCustomRange && (
            <>
              <ControlledDatePicker
                maxDate={dayjs(watch('EndTime')).tz()}
                disableFuture={true}
                name="StartTime"
                label="Start Date"
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().startOf('day') : value;
                }}
              />
              <ControlledDatePicker
                name="EndTime"
                label="End Date"
                minDate={dayjs(watch('StartTime')).tz()}
                disableFuture={true}
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().endOf('day') : value;
                }}
              />{' '}
            </>
          )}

          <div className="button_wrapper">
            <Button
              className="transaction_for_submit"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="transaction_for_submit clear_button"
              onClick={() => {
                reset(defaultTaskValues);
              }}
              label="Clear Filter"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
export default FilterForm;
