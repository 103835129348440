import AddStore, {
  membershipTypeOptions,
} from 'components/Actions/addStoreFront';
import { useAppSelector } from 'hooks/reduxHooks';
import { IStoreFront } from 'interface/settingsInterface';
import { useNavigate, useParams } from 'react-router-dom';
import { Path } from 'router/constant';
import { platform } from 'utils/constants';

const CreateStoreFront = () => {
  const navigate = useNavigate();
  const params = useParams();
  const shopURL =
    useAppSelector(state => state?.shopify?.shopFromSearchParam) || '';
  const shopName = shopURL?.split('.')?.[0];
  const defaultStoreFront: IStoreFront = {
    Name: shopName || '',
    Platform: platform,
    ShopID: shopName || '',
    ID: '',
    Version: 0,
    ShopURL: shopURL ? `https://${shopURL}` : '',
    Shopify: {
      ID: '',
      StorefrontID: '',
      Version: 0,
      AccessToken: '',
      APIKey: '',
      MembershipType: membershipTypeOptions[0].value,
      APISecret: '',
      ShopID: '',
      StorefrontPublicAccessToken: '',
    },
    StoreID: params?.storeID || '',
  };
  return (
    <AddStore
      defaultStoreValues={defaultStoreFront}
      handleClose={() => {
        navigate(Path.SHOPIFY_SETTINGS_PAGE);
      }}
      isAdd={true}
      onSuccess={() => {
        navigate(Path.SHOPIFY_SETTINGS);
      }}
    />
  );
};

export default CreateStoreFront;
