import { useState, useEffect } from 'react';
import { DropZone, usePuck, FieldLabel, ComponentConfig } from '@measured/puck';
import { CartTotal } from '../../../../page-builder/components/cart/cart-total/CartTotal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { SketchPicker } from 'react-color';
import {
  PaddingProps,
  paddingFields,
} from '../../../puck/reusable-props/padding';
import './styles.scss';
import { ReduxSelectorProps } from '../../../services/constants';
import CustomTooltip from '../../../../components/common/tooltip';
import InputField from '../../../../components/common/input-field';
import {
  ShowDropDownProps,
  showDropDownFields,
} from '../../../puck/reusable-props/showDropDown';
import { useLocation } from 'react-router-dom';
import { Path } from '../../../utils/constants';

export type ShowDropDown = {
  tablet: boolean;
  mobile: boolean;
};

export type SplitLayoutProps = PaddingProps &
  ShowDropDownProps & {
    border: boolean;
    bgColor: string;
    bgColorLeft: string;
    bgColorRight: string;
    borderColor: string;
    orderSummaryText: string;
    orderSummaryTextColor: string;
    orderSummaryCartTotal: boolean;
    ennableSticky: string;
  };

export const SplitLayout = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<SplitLayoutProps> => {
  return {
    label: (
      <CustomTooltip title="SplitLayout">
        <span>Simple Split Layout</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...paddingFields,
      ...showDropDownFields,
      border: {
        type: 'radio',
        label: 'Border',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      ennableSticky: {
        type: 'select',
        label: 'Position Sticky',
        options: [
          { label: 'None', value: 'none' },
          { label: 'Auto', value: 'auto' },
          { label: 'Left', value: 'left' },
          { label: 'Right', value: 'right' },
        ],
      },
      borderColor: {
        type: 'custom',
        label: 'Border Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Border Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColor: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorLeft: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color(Left Column)">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      bgColorRight: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Background Color(Right Column)">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryText: {
        type: 'custom',
        label: 'Order Summary Text',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      orderSummaryTextColor: {
        type: 'custom',
        label: 'Summary Text Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Text Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      orderSummaryCartTotal: {
        type: 'radio',
        label: 'Mobile Summary Cart Total',
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
    },
    defaultProps: {
      border: true,
      showDropDown: {
        tablet: true,
        mobile: true,
      },
      ennableSticky: 'right',
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      borderColor: '#E5E5E5',
      bgColor: '#FFFFFF',
      bgColorLeft: '#FFFFFF',
      bgColorRight: '#F9F9F9',
      orderSummaryCartTotal: true,
      orderSummaryTextColor: '#000',
      orderSummaryText: 'Show order summary',
    },
    render: ({
      border,
      bgColorLeft,
      bgColorRight,
      bgColor,
      padding,
      borderColor,
      orderSummaryCartTotal,
      orderSummaryText,
      orderSummaryTextColor,
      ennableSticky,
      showDropDown = { tablet: true, mobile: true },
    }) => {
      const [dropDown, setDropDown] = useState(false);
      const [total, setTotal] = useState(true);
      const { pathname } = useLocation();
      const toggleDropZone = () => setDropDown(!dropDown);
      const puck = usePuck();

      useEffect(() => {
        const handleResize = () => {
          let width = window.innerWidth;
          if (pathname.includes(Path.PAGE_BUILDER_EDIT)) {
            width = puck.appState.ui.viewports.current.width;
          }
          if (width >= 1024) {
            setDropDown(true);
          } else if (width >= 768) {
            setDropDown(showDropDown.tablet);
          } else {
            setDropDown(showDropDown.mobile);
          }
          setTotal(width <= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [showDropDown, pathname, puck.appState.ui.viewports.current.width]);

      return (
        <>
          <div
            className="split-layout header-dropdzone"
            style={{
              backgroundColor: bgColorLeft,
            }}>
            <DropZone zone="top" />
          </div>
          <div
            className={`split-layout-grid ${border ? 'lg:border-t-2' : ''} sm:max-w-8xl pb-14 mx-auto gap-x-8 ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
          ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
          ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`}
            style={{
              borderColor: borderColor,
              backgroundColor: bgColor,
            }}>
            <div
              className={`left-split-layout mx-auto lg:max-w-none split-layout-child px-4`}
              style={{ width: '50%', backgroundColor: bgColorLeft }}>
              <div
                className={`${ennableSticky === 'left' || ennableSticky === 'auto' ? 'sticky top-0' : ''}`}>
                <DropZone zone="left" />
              </div>
            </div>
            <div
              className={`right-split-layout split-layout-child ${border ? 'lg-max:border-l-0 border-l-2' : ''} px-4`}
              style={{
                backgroundColor: bgColorRight,
                width: '50%',
              }}>
              <div
                className={`${ennableSticky === 'right' || ennableSticky === 'auto' ? 'sticky top-0' : ''}`}>
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="flex items-center display-on-mobile-only"
                    style={{ color: orderSummaryTextColor }}
                    onClick={toggleDropZone}>
                    {orderSummaryText}
                    <ChevronDownIcon
                      className={`h-5 w-5 ml-2 transition-transform duration-200 ${
                        dropDown ? 'transform rotate-180' : ''
                      }`}
                      aria-hidden="true"
                    />
                  </button>
                  {total && orderSummaryCartTotal && (
                    <CartTotal useAppSelector={useAppSelector} />
                  )}
                </div>
                {dropDown && <DropZone zone="right" />}
              </div>
            </div>
          </div>
        </>
      );
    },
  };
};
