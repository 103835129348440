import { IDefaultOption } from 'interface/customerInterface';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneRegex = /^[+\d\s()-_]{10,15}$/;

export const numberRegex = new RegExp('^\\d+$');

export const urlPattern =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

export const stepperSteps = ['Store', 'Shopify', 'Payment Gateway'];

export const environments = {
  development: 'development',
  production: 'production',
  staging: 'staging',
  preprod: 'preprod',
  e2e: 'e2e',
};

export const inviteUserStatus = {
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
};

export const currentEnvironment =
  process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT ?? '';

export const resourceTypes = {
  customers: 'customer',
  orders: 'order',
  charges: 'transaction',
  events: 'event',
};

export const trafficChannelData = [
  { label: 'TikTok', value: 'tiktok' },
  { label: 'FaceBook', value: 'facebook' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Google', value: 'google' },
  { label: 'Twitter', value: 'twitter' },
];

export const subscriptionStatus: IDefaultOption[] = [
  { label: 'Active', value: 'active' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Salvage', value: 'salvage' },
  { label: 'Paused', value: 'paused' },
];

export const paymentStatus = [
  { label: 'Success', value: 'success' },
  { label: 'Failed', value: 'failed' },
  { label: 'Voided', value: 'voided' },
  { label: 'Refunded', value: 'refunded' },
];

export const YesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const queueData = [
  { label: 'Authorize', value: 'authorize' },
  { label: 'Capture', value: 'capture' },
  { label: 'Clear Cart', value: 'clear_cart' },
  { label: 'Create Order', value: 'create_order' },
  { label: 'Create Subscription', value: 'create_subscription' },
  { label: 'Purchase Attempt', value: 'purchase_attempt' },
  { label: 'Refund Orders', value: 'refund_orders' },
  { label: 'Start Klaviyo Upload', value: 'start_klaviyo_upload' },
  { label: 'Track Purchase', value: 'track_purchase' },
];

export const statusOption = [
  { label: 'Completed', value: 'completed' },
  { label: 'Deadlettered', value: 'deadlettered' },
  { label: 'Pending', value: 'pending' },
];

export const adminOptions = [
  { label: 'All', value: 'all' },
  { label: 'Show super admin', value: 'true' },
  { label: 'Show users', value: 'false' },
];
export const DisputeAlertsData = [
  {
    label: 'Ethoca',
    value: 'Ethoca',
  },
  {
    label: 'RDR',
    value: 'RDR',
  },
  {
    label: 'CDRN',
    value: 'CDRN',
  },
  {
    label: 'Chargeback',
    value: 'Chargeback',
  },
];

export const YesNoOptionsWithTrueFalse = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const billingTypeData = [
  { label: 'All', value: 'all' },
  { label: 'Purchase', value: 'purchase' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'Upsell', value: 'upsell' },
];
export const transactionStatus: IDefaultOption[] = [
  { label: 'Captured', value: 'captured' },
  { label: 'Fail Authorization', value: 'fail_authorization' },
  { label: 'Voided', value: 'voided' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Partial Refund', value: 'partial_refund' },
  { label: 'Chargeback', value: 'chargeback' },
  { label: 'Fail Capture', value: 'fail_capture' },
  { label: 'Authorized', value: 'authorized' },
];

export const tabNames = {
  transactionHistory: 'Transaction History',
  notes: 'Notes',
  subscriptionHistory: 'Subscription history',
};
export const userPermissionKeys = {
  manage: 'manage',
  view: 'view',
  all: 'all',
};

export const addEditName = {
  store: 'store',
  subscription: 'subscription',
  storeFront: 'storeFront',
  paymentGateway: 'paymentGateway',
  user: 'user',
};

export const defaultUserPermission = {
  Email: '',
  Permissions: ['view:all'],
  SuperAdmin: false,
};

export const tabName = [
  'Shopify',
  'Payment Gateway',
  'Subscription Plan',
  'User Permissions',
];

export const platform = 'shopify';

export const superAdmin = 'super_admin';

const visa = [
  { label: 'Merchandise / Services Not Received', value: '13.1' },
  { label: 'Cancelled Recurring Transaction', value: '13.2' },
  {
    label: 'Not as Described or Defective Merchandise / Services',
    value: '13.3',
  },
  { label: 'Counterfeit Merchandise', value: '13.4' },
  { label: 'Misrepresentation', value: '13.5' },
  { label: 'Credit Not Processed', value: '13.6' },
  { label: 'Cancelled Merchandise / Services', value: '13.7' },
  { label: 'Original Credit Transaction Not Accepted', value: '13.8' },
  { label: 'Non-Receipt of Cash or Load Transaction Value', value: '13.9' },
];

const mastercard = [
  { label: 'Cardholder Dispute - “No Show” Hotel Charge', value: '4853' },
  { label: 'Cardholder Dispute - Addendum Dispute', value: '4853' },
  {
    label: 'Cardholder Dispute - Cardholder Dispute of a Recurring Transaction',
    value: '4853',
  },
  { label: 'Cardholder Dispute - Counterfeit Goods', value: '4853' },
  { label: 'Cardholder Dispute - Credit Not Processed', value: '4853' },
  { label: 'Cardholder Dispute - Credit Posted as a Purchase', value: '4853' },
  {
    label: 'Cardholder Dispute - Digital Goods Purchase of $25 or Less',
    value: '4853',
  },
  {
    label:
      'Cardholder Dispute - Goods or Services Not as Described or Defective',
    value: '4853',
  },
  {
    label: 'Cardholder Dispute - Goods or Services Not Provided',
    value: '4853',
  },
  {
    label: 'Cardholder Dispute - Issuer Dispute of a Recurring Transaction',
    value: '4853',
  },
  { label: 'Cardholder Dispute - Timeshares', value: '4853' },
  { label: 'Cardholder Dispute - Transaction Did Not Complete', value: '4853' },
  { label: 'Goods or Services Not Provided', value: '4855' },
  { label: 'Addendum, No-Show, or ATM Dispute - Europe', value: '4859' },
  {
    label: 'Addendum, No-Show, or ATM Dispute - Non-European Bank',
    value: '4859',
  },
  { label: 'Credit Not Processed', value: '4860' },
];

const amex = [
  { label: 'unknown', value: '' },
  { label: 'Credit Not Processed', value: 'C02' },
  { label: 'Goods / Services Returned or Refused', value: 'C04' },
  { label: 'Goods / Services Cancelled', value: 'C05' },
  {
    label: 'Goods / Services Not Received or Only Partially Received',
    value: 'C08',
  },
  { label: 'Paid by Other Means', value: 'C14' },
  { label: 'No Show or CARDeposit Cancelled', value: 'C18' },
  { label: 'Cancelled Recurring Billing', value: 'C28' },
  { label: 'Goods / Services Not As Described', value: 'C31' },
  { label: 'Goods / Services Damaged or Defective', value: 'C32' },
];

const discover = [
  { label: 'unknown', value: '' },
  { label: 'Good Faith Investigation Chargeback', value: '05' },
  { label: 'Does Not Recognize', value: 'AA' },
  { label: 'Recurring Payments', value: 'AP' },
  { label: 'Duplicate Processing', value: 'DP' },
  { label: 'Altered Amount', value: 'AW' },
  { label: 'Credit / Debit Posted Incorrectly', value: 'CD' },
  { label: 'Non-Receipt of Cash from an ATM', value: 'NF' },
  { label: 'Paid By Other Means', value: 'PM' },
  { label: 'Non-Receipt of Goods, Services, or Cash', value: 'RG' },
  {
    label: 'Cardholder Disputes the Quality of Goods or Services',
    value: 'RM',
  },
  { label: 'Credit Not Processed', value: 'RN2' },
];

export const DisputeCodes = (cardType: string) => {
  switch (cardType?.toLocaleLowerCase()) {
    case 'visa':
      return visa;
    case 'mastercard':
    case 'mc':
      return mastercard;
    case 'amex':
      return amex;
    case 'discover':
      return discover;
    default:
      return [];
  }
};

export const allCardCodes = {
  visa,
  mastercard,
  amex,
  discover,
};

export const viewPorts = {
  mobile: 360,
  tablet: 768,
  desktop: 1280,
};
