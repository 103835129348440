import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import pageBuilderService from '../../api/pageBuilderService';
import {
  IMemberShipProductsDetails,
  IVariant,
} from '../../interface/pageInterface';
import { AxiosInstance } from 'axios';
import Select from '../../../components/common/select';
import { Loader } from '../../../components/common/loader';
import CustomTooltip from '../../../components/common/tooltip';
import { FieldLabel } from '@measured/puck';
import { ReduxSelectorProps } from '../../services/constants';

export type CartProductsPropsCustom1 = {
  value: CartProductsProps;
  onChange: (_value: CartProductsProps) => void;
  baseService: AxiosInstance;
} & ReduxSelectorProps;

export interface CartProductsProps {
  product: IMemberShipProductsDetails;
  variant: IVariant;
  varientOptions: IVariant[];
  selectMethod: string;
}

const selectOptions = [{ label: 'Product', value: 'product' }];

const ProductsCheckbox: React.FC<CartProductsPropsCustom1> = ({
  value,
  onChange,
  baseService,
  useAppSelector,
}: CartProductsPropsCustom1) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [variantLoading, setVariantLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<IMemberShipProductsDetails[]>([]);
  const storeData = useAppSelector(state => state.store.storeData);
  const storeID = searchParams.get('storeID') || '';
  const store = searchParams.get('store') || '';

  const getBatchProducts = async (product: IMemberShipProductsDetails) => {
    if (!storeData?.ShopID || !storeData?.StoreID || !product.ID) return;

    setVariantLoading(true);
    const response = await pageBuilderService.getProductsWithVariant(
      {
        ProductIDs: [Number(product?.ID?.split('/').pop())],
        ShopID: storeData?.ShopID,
        StoreID: storeData?.StoreID,
      },
      baseService,
    );
    onChange({
      ...value,
      variant: (response?.data?.Result[0]?.Variants as IVariant[])?.some(
        variant => variant?.ID === value?.variant?.ID,
      )
        ? value?.variant
        : (response?.data?.Result[0]?.Variants[0] as IVariant)?.Title === ''
          ? { ...response?.data?.Result[0]?.Variants[0], Title: product.Title }
          : response?.data?.Result[0]?.Variants[0],
      varientOptions: response?.data?.Result[0]?.Variants,
    });
    setVariantLoading(false);
  };

  const getPages = async () => {
    setLoading(true);
    const res = await pageBuilderService.getAllProducts(
      {
        ShopID: store,
        Limit: 25,
        StoreID: storeID,
      },
      baseService,
    );
    setLoading(false);
    if (res.data?.Result) setProducts(res.data?.Result || []);
    else setProducts([]);
  };

  useEffect(() => {
    if (value?.selectMethod === 'product') {
      getPages();
    }
  }, [value?.selectMethod]);

  useEffect(() => {
    if (value?.product) {
      getBatchProducts(value?.product);
    }
  }, [value?.product]);

  return (
    <div className="flex flex-col gap-4">
      <FieldLabel
        label={
          (
            <CustomTooltip title="Text">
              <span>Select Method</span>
            </CustomTooltip>
          ) as React.ReactNode as string
        }
        el="div">
        <Select
          options={selectOptions}
          placeholder="Select Method"
          onChange={selectMethodValue => {
            const valueOfOptions = selectOptions.find(
              item => item?.value === selectMethodValue,
            );
            if (valueOfOptions) {
              onChange({ ...value, selectMethod: valueOfOptions?.value });
            }
          }}
          value={value?.selectMethod}
        />
      </FieldLabel>
      {value?.selectMethod === 'product' && (
        <>
          <div className="relative">
            <Loader loading={loading} />
            <FieldLabel
              label={
                (
                  <CustomTooltip title="Cart">
                    <span>Select Product</span>
                  </CustomTooltip>
                ) as React.ReactNode as string
              }
              el="div">
              <Select
                value={value?.product?.ID}
                placeholder="Select Product"
                onChange={itemId => {
                  const product = products?.find(item => item?.ID === itemId);
                  if (product) {
                    onChange({ ...value, product: product });
                  }
                }}
                options={products?.map(product => ({
                  label: product?.Title,
                  value: product?.ID,
                }))}
              />
            </FieldLabel>
          </div>
          {value.varientOptions?.length > 0 && (
            <div className="relative">
              <Loader loading={variantLoading} />
              <FieldLabel
                label={
                  (
                    <CustomTooltip title="Cart">
                      <span>Select Variant</span>
                    </CustomTooltip>
                  ) as React.ReactNode as string
                }
                el="div">
                <Select
                  value={value.variant?.ID}
                  placeholder="Select Variant"
                  onChange={itemId => {
                    const variant = value.varientOptions?.find(
                      item => item?.ID === itemId,
                    );
                    if (variant) {
                      onChange({ ...value, variant: variant });
                    }
                  }}
                  options={value.varientOptions?.map(variant => ({
                    label:
                      variant?.Title !== ''
                        ? variant?.Title
                        : value?.product?.Title,
                    value: variant?.ID,
                  }))}
                />
              </FieldLabel>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductsCheckbox;
