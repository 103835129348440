import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PopOver from 'components/common/popOver';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IOrder } from 'interface/orderInterface';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import 'components/orders/cart/style.scss';

const defaultAmount = '0.00';

interface IProps {
  row: IOrder;
}
const Cart: React.FC<IProps> = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor position to the clicked button
  };

  return (
    <>
      <div className="order-cart">
        {row.Items?.length}
        <button onClick={e => handleOpenPopover(e)}>
          <KeyboardArrowDownIcon />
        </button>
      </div>
      <PopOver
        child={
          <div>
            <div className="cart-items">
              <div className="cart-title">Cart Items</div>
              <div className="carts-list">
                {row.Items &&
                  row.Items.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <div className="product-wrap">
                          <div>
                            <img
                              src={item?.ImageURL || PlaceholderIcon}
                              alt="Product"
                              className="product-image"
                            />
                          </div>
                          <div className="item-content">
                            <p className="item-name">{item?.Name}</p>
                            {item?.Subtitle && (
                              <div className="product-description">
                                Variant: {item?.Subtitle}
                              </div>
                            )}
                            <div className="flex justify-between w-full">
                              <div className="qty-wrap flex !items-start w-fit">
                                Quantity: {item?.Quantity}&nbsp;
                                <CloseIcon fontSize="small" /> &nbsp;
                                <span>
                                  $
                                  {item?.UnitPrice ||
                                    item?.SalePrice ||
                                    defaultAmount}
                                </span>
                              </div>
                              <div className="flex flex-col items-end w-fit">
                                <div className="total-product-amount">
                                  $
                                  {Number(item?.UnitPrice) * item?.Quantity ||
                                    Number(item?.SalePrice) * item?.Quantity ||
                                    defaultAmount}
                                </div>
                                {parseFloat(item?.DiscountAmount) > 0 && (
                                  <span className="discount-amount">
                                    $
                                    {parseFloat(item?.DiscountAmount) +
                                      parseFloat(item?.UnitPrice) *
                                        item?.Quantity}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="summary-content">
                <div className="summary-container">
                  <div className="sum-header">Order Summary</div>
                  <div className="sum-content">
                    <p>
                      Subtotal <span>${row.SubTotalAmount}</span>
                    </p>
                    <p>
                      Estimatedtax Amount <span>${row.EstimatedTaxAmount}</span>
                    </p>
                    {Number(row.DiscountAmount) > 0 && (
                      <p>
                        Discount Amount{' '}
                        <span className="discount-price">
                          ${row.DiscountAmount}
                        </span>
                      </p>
                    )}
                    <p className="total">
                      Total <span>${row.TotalAmount}</span>
                    </p>
                    <p>
                      Paid <span>${row.TotalAmount}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        onClose={handleClosePopover}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default Cart;
