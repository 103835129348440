import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FieldLabel, Fields } from '@measured/puck';
import { Switch, FormGroup, FormControlLabel, Tooltip } from '@mui/material';
import { CustomTooltip } from 'shared-components';

export type ShowDropDownProps = {
  showDropDown: {
    tablet: boolean;
    mobile: boolean;
  };
};

export const showDropDownFields: Fields<ShowDropDownProps> = {
  showDropDown: {
    type: 'custom',
    label: 'Default Collapse',
    render: ({ value, onChange }) => {
      const currentValue = value || { tablet: true, mobile: false };

      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleChange =
        (device: keyof ShowDropDownProps['showDropDown']) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...currentValue, [device]: event.target.checked });
        };

      return (
        <>
          <div
            className=" flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <FieldLabel
              label={
                (
                  <CustomTooltip title="Mobile Order Details">
                    <span>Toggle Order Summary</span>
                  </CustomTooltip>
                ) as React.ReactNode as string
              }
            />
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {isOpen && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={value?.tablet || false}
                    onChange={handleChange('tablet')}
                  />
                }
                label={
                  <Tooltip title="Show dropdown on tablet devices">
                    <span>Tablet</span>
                  </Tooltip>
                }
                labelPlacement="start"
                sx={{
                  margin: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={value?.mobile || false}
                    onChange={handleChange('mobile')}
                  />
                }
                label={
                  <Tooltip title="Show dropdown on mobile devices">
                    <span>Mobile</span>
                  </Tooltip>
                }
                labelPlacement="start"
                sx={{
                  margin: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              />
            </FormGroup>
          )}
        </>
      );
    },
  },
};
