import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import './style.scss';
import React from 'react';
import { ITasksData } from 'interface/tasks';
interface IProps {
  row: ITasksData;
}

const NestedTableComponent: React.FC<IProps> = ({ row }) => {
  return (
    <div className="nestedRowTask">
      <Box sx={{ margin: 1 }}>
        <div className="task_container">
          <div className="task_top_container">
            {row && (
              <TableContainer className="tasks_table_container">
                <Table
                  className="tasks_table"
                  aria-labelledby="tableTitle"
                  stickyHeader>
                  <TableHead className="table_header">
                    <TableRow>
                      <TableCell className="table_header_cell">
                        Message
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body">
                    <TableRow className="table_row" key={'ID'}>
                      <TableCell className="table_cell text-balance">
                        {row?.Message ? row?.Message : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default NestedTableComponent;
