import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Button } from 'shared-components';
import { ControlledRadioButton } from 'components/common/radioButton';
import 'components/transactions/transactionFilter/filterForm/style.scss';
import { FC } from 'react';
import { useState } from 'react';
import { ControlledDatePicker } from 'components/common/datePicker';

import MultiSelect, {
  ControlledMultiSelect,
} from 'components/common/multiSelect';
import { MultipleInputTag } from 'components/common/multiInput';
import { ITransactionFormFilter } from 'interface/transactionInterface';
import { defaultTransactionFilterValues } from 'components/transactions';
import {
  billingTypeData,
  DisputeAlertsData,
  emailRegex,
  phoneRegex,
  trafficChannelData,
  transactionStatus,
} from 'utils/constants';
import { IDefaultOption } from 'interface/customerInterface';
import TextField, { TextFieldControl } from 'components/common/textField';
import { dateRange, dateRangeOptions } from 'components/dashboard/constant';
import { getDateFilter } from 'utils/helper';
import dayjs from 'dayjs';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import MerchantSelect from 'components/common/MerchantSelect';

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  sideFormFilter: ITransactionFormFilter;
  filterSubmission: (_data: ITransactionFormFilter) => void;
  resetSelected: () => void;
  loading: number;
}

const FilterForm: FC<IProps> = ({
  sideFormFilter,
  filterSubmission,
  resetSelected,
  loading,
}) => {
  const [isCustomRange, setCustomRange] = useState<boolean>(
    !!sideFormFilter.DateRange,
  );
  const methods = useForm<ITransactionFormFilter>({
    defaultValues: sideFormFilter,
  });
  const { handleSubmit, control, reset, setValue, watch, setError } = methods;

  const onSubmit = (data: ITransactionFormFilter) => {
    filterSubmission(data);
    resetSelected();
  };

  return (
    <div className="filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <FormProvider {...methods}>
          <Controller
            name="CustomerEmails"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value}
                  label="Customer Emails"
                  isLowerCase={true}
                  placeholder="Type Emails"
                  validation={[
                    {
                      validationRule: emailRegex,
                      validationMessage: 'Enter a valid email address',
                    },
                  ]}
                  setError={(value: string) => {
                    setError('CustomerEmails', {
                      type: 'custom',
                      message: value,
                    });
                  }}
                  errorString={error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            name="PhoneNumbers"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value}
                  label="Customer Phone Numbers"
                  placeholder="Type Phone Numbers"
                  validation={[
                    {
                      validationRule: phoneRegex,
                      validationMessage: 'Enter a valid phone number',
                    },
                  ]}
                  setError={(value: string) => {
                    setError('PhoneNumbers', {
                      type: 'custom',
                      message: value,
                    });
                  }}
                  errorString={error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            name="FirstName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  label="First Name"
                  placeholder="Type First Name"
                  value={value}
                  fixedSize="md"
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                />
              );
            }}
          />
          <Controller
            name="LastName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  label="Last Name"
                  placeholder="Type Last Name"
                  value={value}
                  fixedSize="md"
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                />
              );
            }}
          />
          <Controller
            name="ExternalProcessorID"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <MerchantSelect
                  label="External Processor ID"
                  placeholder="Type External Processor ID"
                  onChange={val => {
                    onChange(val || null);
                  }}
                  value={value}
                />
              );
            }}
          />
          <Controller
            name="Tags"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value}
                  label="Tag type"
                  placeholder="Type Tag"
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            name="DateRange"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const handleChange = (e: string) => {
                if (e !== 'Custom Range') {
                  const { start_time, end_time } = getDateFilter(e);
                  setValue('StartTime', start_time);
                  setValue('EndTime', end_time);
                } else {
                  setValue('StartTime', null);
                  setValue('EndTime', null);
                }
                setCustomRange(true);
                onChange(e);
              };
              return (
                <AutoCompleteSelect
                  options={dateRangeOptions}
                  labelKey="label"
                  onChange={val => handleChange(val?.value)}
                  value={
                    dateRangeOptions?.find(type => type.value === value) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                  label="Date Range"
                />
              );
            }}
          />
          {isCustomRange && (
            <>
              <ControlledDatePicker
                maxDate={dayjs(watch('EndTime')).tz()}
                disableFuture={true}
                name="StartTime"
                label="Start Date"
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().startOf('day') : value;
                }}
              />
              <ControlledDatePicker
                name="EndTime"
                label="End Date"
                minDate={dayjs(watch('StartTime')).tz()}
                disableFuture={true}
                onAccept={() => {
                  setValue('DateRange', dateRange.customRange);
                }}
                valueHandler={(value: string) => {
                  return value ? dayjs(value).tz().endOf('day') : value;
                }}
              />{' '}
            </>
          )}
          <ControlledMultiSelect<IDefaultOption>
            name="Status"
            options={transactionStatus}
            labelKey="label"
            valueKey="value"
            label="Transaction Status"
            placeholder="Select Transaction Status"
            limitTags={1}
          />
          <Controller
            name="DisputeAlerts"
            control={control}
            render={({ field: { ref, onChange, value } }) => {
              //converting array of IOption to array of string
              const handleChange = (newval: IOption[]) => {
                onChange(newval.map(data => data.value));
              };
              // converting array of string to array of IOptions
              const parsedValue = DisputeAlertsData?.filter(channels =>
                value.includes(channels?.value),
              );
              return (
                <MultiSelect<IOption>
                  value={parsedValue}
                  options={DisputeAlertsData}
                  labelKey="label"
                  label="Dispute Alert"
                  placeholder="Select Dispute Alert"
                  ref={ref}
                  selectAll={true}
                  valueKey="value"
                  className="dispute_alert"
                  limitTags={1}
                  onChange={(newValue: IOption[]) => {
                    handleChange(newValue);
                  }}
                />
              );
            }}
          />
          <Controller
            name="TrafficChannel"
            control={control}
            render={({ field: { ref, onChange, value } }) => {
              //converting array of IOption to array of string
              const handleChange = (newval: IOption[]) => {
                onChange(newval.map(data => data.value));
              };
              // converting array of string to array of IOptions
              const parsedValue = trafficChannelData?.filter(channels =>
                value.includes(channels?.value),
              );
              return (
                <MultiSelect<IOption>
                  value={parsedValue}
                  options={trafficChannelData}
                  labelKey="label"
                  label="Traffic Channel"
                  placeholder="Select Traffic Channel"
                  ref={ref}
                  valueKey="value"
                  limitTags={1}
                  onChange={(newValue: IOption[]) => {
                    handleChange(newValue);
                  }}
                />
              );
            }}
          />

          <TextFieldControl
            name="CCLast"
            className="filter_form_input"
            placeholder="Last 4 CC Digit"
            label="Last 4 CC Digits"
            fixedSize="md"
            onChangeHandler={value => value.replace(/[^0-9]/g, '')}
            rules={{
              validate: (value: string) => {
                if (value.length != 0 && value.length != 4) {
                  return 'Length should be four';
                }
              },
            }}
          />

          <div className="common_box">
            <p className="common_input_label">Cycles Completed</p>
            <div className="flexContainer">
              <Controller
                name="MinCycle"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      className="filter_form_input"
                      placeholder="Min value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(e.target.value.replace(/[^0-9]/g, ''))
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
              <Controller
                name="MaxCycle"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      className="filter_form_input"
                      placeholder="Max Value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(e.target.value.replace(/[^0-9]/g, ''))
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
          </div>
          <ControlledRadioButton
            name="BillingType"
            label="Billing Type"
            className="filter_form_input"
            options={billingTypeData}
            onChangeHandler={(data: string) =>
              data === 'all' ? ['purchase', 'subscription', 'upsell'] : [data]
            }
            newValueHandler={(data: string[]) =>
              data.length === 1 ? data[0] : 'all'
            }
          />

          <div className="button_wrapper">
            <Button
              className="transaction_for_submit"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="transaction_for_submit clear_button"
              onClick={() => {
                if (!loading) {
                  reset(defaultTransactionFilterValues);
                }
              }}
              label="Clear Filter"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};
export default FilterForm;
