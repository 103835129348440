import React, { ReactNode, useRef, useState } from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/doller-blue.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import 'components/subscriptionProjection/style.scss';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import NestedTableComponent from 'components/transactions/transactionNestedTable';
import { get } from 'lodash';
import ProjectionNestedTable from 'components/subscriptionProjection/projectionNestedTable';
import SideFilter from 'components/subscriptionProjection/projectionFilter/sideFilter';
import { useSearchParams } from 'react-router-dom';
import { IStore } from 'interface/storeInterface';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ISubscriptionProjectionData) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
}

interface ISubscriptionProjectionData {
  date: string;
  activeSubscribers: string;
  subscriptionInSalvage: string;
  total: number;
  potentialRevenue: number;
  projectedRevenue: number;
}

const mockDataSubscriptionProjection = [
  {
    date: '2024-05-20',
    activeSubscribers: 'John Doe',
    subscriptionInSalvage: 'ORD12345',
    total: 250.0,
    potentialRevenue: 275.0,
    projectedRevenue: 260.0,
  },
  {
    date: '2024-05-19',
    activeSubscribers: 'Jane Smith',
    subscriptionInSalvage: 'ORD12346',
    total: 150.0,
    potentialRevenue: 170.0,
    projectedRevenue: 160.0,
  },
  {
    date: '2024-05-18',
    activeSubscribers: 'Emily Johnson',
    subscriptionInSalvage: 'ORD12347',
    total: 100.0,
    potentialRevenue: 120.0,
    projectedRevenue: 110.0,
  },
  {
    date: '2024-05-17',
    activeSubscribers: 'Michael Brown',
    subscriptionInSalvage: 'ORD12348',
    total: 200.0,
    potentialRevenue: 220.0,
    projectedRevenue: 210.0,
  },
  {
    date: '2024-05-16',
    activeSubscribers: 'Linda Davis',
    subscriptionInSalvage: 'ORD12349',
    total: 300.0,
    potentialRevenue: 330.0,
    projectedRevenue: 315.0,
  },
];

const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    label: 'Date',
    key: 'date',
  },
  {
    id: 'activeSubscribers',
    label: 'Active Subscribers',
    key: 'customer_name',
  },
  {
    id: 'subscriptionInSalvage',
    label: 'Subscription In Salvage',
    key: 'shopify_order_number',
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'total',
    label: 'Total',
    key: 'type',
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'potentialRevenue',
    label: 'Potential Revenue',
    key: 'type',
    hide: false,
    showSortIcon: true,
  },
  {
    id: 'projectedRevenue',
    label: 'Projected Revenue',
    key: 'type',
    hide: false,
    showSortIcon: true,
  },
];
export interface IProjectionFilter {
  StoreIDs: IStore[];
  merchantAccount: string[];
}

export interface IProjectioActionFilter {
  subscriptionValue: string;
  cycle1: string;
  cycle2: string;
  cycle3: string;
  cycle4: string;
  cycle5: string;
  cycle6: string;
  cycle7: string;
  terminalRentention: string;
}

export default function SubscriptionProjection() {
  const [headCellList] = useState<HeadCell[]>([...headCells]);
  const ref = useRef<HTMLDivElement>(null);
  const [width] = useState<number | undefined>(undefined);
  const [openRows, setOpenRows] = useState<boolean[]>();
  const [searchParams] = useSearchParams();
  const [sideFormFilter] = useState<IProjectionFilter>({
    StoreIDs: searchParams.get('StoreIDs')
      ? JSON.parse(searchParams.get('StoreIDs') || '')
      : [],
    merchantAccount: searchParams.get('merchantAccount')
      ? JSON.parse(searchParams.get('merchantAccount') || '')
      : [],
  });

  const [sideActionFilter] = useState<IProjectioActionFilter>({
    cycle1: '',
    cycle2: '',
    cycle3: '',
    cycle4: '',
    cycle5: '',
    cycle6: '',
    cycle7: '',
    terminalRentention: '',
    subscriptionValue: '',
  });
  const handleRowToggle = (index: number) => {
    const newOpenRows = [...(openRows ?? [])];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };
  return (
    <>
      <div className="subscription-projection-page">
        <div className="sample_div">
          <div className="subscription-projection-cards">
            <div className="card">
              <div className="icon_wrapper">
                <UserIcon className="active_subscribers" />
              </div>
              <div className="content_wrapper">
                <p className="label">Active Subscribers</p>
                <p className="sub_text">327</p>
              </div>
            </div>
            <div className="card">
              <div className="icon_wrapper">
                <UserIcon className="subscribers_in_salvage" />
              </div>
              <div className="content_wrapper">
                <p className="label">Subscribers in Salvage</p>
                <p className="sub_text">245</p>
              </div>
            </div>
            <div className="card">
              <div className="icon_wrapper">
                <DollarIcon className="salvage_revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Potential Revenue</p>
                <p className="sub_text">$3,997.00</p>
              </div>
            </div>
            <div className="card">
              <div className="icon_wrapper">
                <DollarIcon className="projected_revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Projected Revenue</p>
                <p className="sub_text">$2,997.00</p>
              </div>
            </div>
          </div>
          <div className="subscription-projection_container">
            <div className="transaction_top_container">
              <TableContainer
                className="transactions_table_container Common_Table"
                ref={ref}>
                <Table
                  className="transactions_table"
                  aria-labelledby="tableTitle"
                  stickyHeader>
                  <TableHead className="table_header">
                    <TableRow>
                      <TableCell className="table_header_cell" />
                      <TableCell className="table_header_cell"></TableCell>
                      {headCellList?.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_header_cell"
                            key={headCell.key}>
                            {headCell.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body">
                    {mockDataSubscriptionProjection.length > 0 ? (
                      mockDataSubscriptionProjection.map((row, index) => {
                        return (
                          <>
                            <TableRow
                              hover
                              className="table_row"
                              role="checkbox"
                              tabIndex={-1}
                              key={row.date}>
                              <TableCell className="table_cell">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  className="expand-row"
                                  onClick={() => handleRowToggle(index)}>
                                  {(openRows ? openRows[index] : false) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowRightIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell
                                padding="checkbox"
                                className="table_cell"></TableCell>
                              {headCells.map(headCell => {
                                return (
                                  <TableCell
                                    className="table_cell"
                                    key={headCell.label}
                                    component="th"
                                    id={headCell.key}
                                    scope="row">
                                    {headCell?.cellRender
                                      ? headCell.cellRender(row)
                                      : get(row, headCell.id)}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                            {(openRows ? openRows[index] : false) && (
                              <TableRow className="row_collapsed">
                                <TableCell
                                  className="table_border_none nested-table-cell"
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={9}>
                                  <Collapse
                                    in={openRows ? openRows[index] : false}
                                    timeout="auto"
                                    unmountOnExit>
                                    <div
                                      style={{ width: width ? width : 'auto' }}>
                                      {/* <NestedTableComponent row={row} /> */}
                                      <ProjectionNestedTable />
                                    </div>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell className="!py-20 !left-[50%] pointer-events-none">
                          <div className="no-data-row">No data found</div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <SideFilter
          sideFormFilter={sideFormFilter}
          sideActionFilter={sideActionFilter}
        />
      </div>
    </>
  );
}
